import React, { useState, useEffect } from "react";
import { collectionGroup, getDocs } from "firebase/firestore";
import { db } from "../../firebase/firebase";

import ring from "../../assets/ringing.png";

import alarm from "../../assets/ringing (1).png";

import "./NotificationBar.css";

const NotificationBar = () => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchDataAndRender = async () => {
      try {
        const warrantsData = fetchDataFromCollectionGroup("warrants");
        const milageData = fetchDataFromCollectionGroup("milage");
        const notificationMilageData = fetchDataFromCollectionGroup(
          "notificationService"
        );
        const servicesData = fetchDataFromCollectionGroup("services");
        const infoData = fetchDataFromCollectionGroup("info");

        // Wait for all data fetching to complete
        const allData = await Promise.all([
          warrantsData,
          milageData,
          notificationMilageData,
          servicesData,
          infoData,
        ]);

        // Combine all data
        const combinedData = allData.flat();
        localStorage.setItem("allData", JSON.stringify(combinedData));
        // Render the data based on the condition
        renderData(combinedData);
      } catch (error) {
        console.error("Error fetching and rendering data: ", error);
      }
    };

    const fetchDataFromCollectionGroup = async (collectionGroupName) => {
      const dataQuerySnapshot = await getDocs(
        collectionGroup(db, collectionGroupName)
      );
      const data = dataQuerySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return data;
    };

    const isWithinNext30Days = (date) => {
      const currentDate = new Date();
      const next30Days = new Date();
      next30Days.setDate(currentDate.getDate() + 30);
      return date >= currentDate && date <= next30Days;
    };

    const renderData = (data) => {
      // Initialize an array to hold the notifications
      let notificationsArray = [];
      const highestMileages = {};

      // Iterate through the data to check conditions
      data.forEach((item) => {
        const car = item.carName;
        const mileage = item.mileage;

        console.log(car, mileage);

        // Check if the car already has a recorded highest mileage
        if (
          highestMileages[car] === undefined ||
          mileage > highestMileages[car]
        ) {
          highestMileages[car] = mileage;
        }

        // Check for warrantyExpiryNumber, manufacturer, model, and licensePlate
        if (
          item.warrantyExpiryNumber &&
          item.manufacturer &&
          item.model &&
          item.licensePlate &&
          isWithinNext30Days(new Date(item.warrantyExpiryNumber))
        ) {
          notificationsArray.push({
            type: "warranty",
            data: {
              warrantyExpiryNumber: item.warrantyExpiryNumber,
              carName: `${item.manufacturer}-${item.model}-${item.licensePlate}`,
              manufacturer: item.manufacturer,
              model: item.model,
              licensePlate: item.licensePlate,
            },
          });
        }
        // if (item.mileage) {
        //   notificationsArray.push({
        //     type: "mileage",
        //     data: {
        //       car: car,
        //       highestMileage: highestMileages.get(car), // Use the highest mileage for this car
        //     },
        //   });
        // }
        if (item.type === "serviceMileage") {
          notificationsArray.push({
            type: "serviceMileage",
            data: {
              carName: item.carName,
              serviceName: item.serviceName,
              nextServiceMileage: +item.nextServiceMileage,
              mileageNumber: +item.mileage + +item.nextServiceMileage,
              highestMileage: highestMileages[car],
              alarm: 0.2 * +item.nextServiceMileage,
            },
          });
        }
        if (item.type === "warrantyMileage") {
          notificationsArray.push({
            type: "warrantyMileage",
            data: {
              carName: item.carName,
              warrantyMileageNumber: item.warrantyMileageNumber,
              highestMileage: highestMileages[car],
              alarm: 0.2 * (+item.warrantyMileageNumber - +item.startMileage),
            },
          });
        }

        // Check for policyExpiryDate, manufacturer, model, and licensePlate
        if (
          item.policyExpiryDate &&
          item.manufacturer &&
          item.model &&
          item.licensePlate &&
          isWithinNext30Days(new Date(item.policyExpiryDate))
        ) {
          notificationsArray.push({
            type: "policy",
            data: {
              policyExpiryDate: item.policyExpiryDate,
              carName: `${item.manufacturer}-${item.model}-${item.licensePlate}`,
              manufacturer: item.manufacturer,
              model: item.model,
              licensePlate: item.licensePlate,
            },
          });
        }

        // Check for registrationDate, manufacturer, model, and licensePlate
        if (
          item.registrationDate &&
          item.manufacturer &&
          item.model &&
          item.licensePlate
          // isWithinNext30Days(new Date(item.againRegistrationDate))
        ) {
          const calculateNextRegistrationDate = (registrationDate) => {
            const date = new Date(registrationDate);
            date.setFullYear(date.getFullYear() + 1);

            // Format the date as needed
            const formattedDate = date.toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            });

            return formattedDate;
          };
          notificationsArray.push({
            type: "registration",
            data: {
              againRegistrationDate: calculateNextRegistrationDate(
                item.registrationDate
              ),
              carName: `${item.manufacturer}-${item.model}-${item.licensePlate}`,
              manufacturer: item.manufacturer,
              model: item.model,
              licensePlate: item.licensePlate,
            },
          });
        }

        // Check for nextServiceDate, serviceName within the next 30 days
        if (
          item.nextServiceDate &&
          item.serviceName &&
          isWithinNext30Days(new Date(item.nextServiceDate))
        ) {
          notificationsArray.push({
            type: "service",
            data: {
              serviceName: item.serviceName,
              nextServiceDate: item.nextServiceDate,
              carName: item.carName,
            },
          });
        }
      });

      // Set the notifications state
      setNotifications(notificationsArray);

      // Log the notifications data
      console.log("Notifications:", notificationsArray);
    };

    // Fetch and render data when the component mounts
    fetchDataAndRender();
  }, []); // The empty dependency array ensures that this effect runs only once, similar to componentDidMount
  // Define a function to determine the image source
  const groupedNotifications = notifications.reduce((grouped, notification) => {
    const carName = notification.data.carName;

    if (!grouped[carName]) {
      grouped[carName] = [];
    }

    grouped[carName].push(notification);

    return grouped;
  }, {});
  const groupedNotificationElements = Object.entries(groupedNotifications).map(
    ([carName, notifications]) => {
      const sortedNotifications = notifications.sort((a, b) => {
        // Define the priority order for notification types
        const priorityOrder = {
          registration: 1,
          policy: 2,
          warranty: 3,
          service: 4,
          warrantyMileage: 5,
          serviceMileage: 6,
        };

        const aPriority = priorityOrder[a.type] || 0;
        const bPriority = priorityOrder[b.type] || 0;

        // If the priorities are equal, sort based on the alarm/ring image
        if (aPriority === bPriority) {
          const aImage = a.type === "warranty" ? alarm : ring;
          const bImage = b.type === "warranty" ? alarm : ring;

          // Sort "alarm" images first, then "ring" images
          if (aImage === alarm && bImage === ring) return -1;
          if (aImage === ring && bImage === alarm) return 1;
        }

        // Sort based on the priority order
        return aPriority - bPriority;
      });

      return (
        <div key={carName} className="car-group">
          <p>{carName}</p>
          {sortedNotifications.map((notification, index) => (
            <div key={index} className="notification">
              {notification.type === "warranty" && (
                <div>
                  <img src={alarm} alt="" />
                  <p>
                    Garancija ističe: {notification.data.warrantyExpiryNumber}{" "}
                    <br />
                    {notification.data.carName}
                  </p>
                </div>
              )}

              {notification.type === "policy" && (
                <div>
                  <img src={alarm} alt="" />
                  <p>
                    Polisa ističe: {notification.data.policyExpiryDate} <br />
                    {notification.data.carName}
                  </p>
                </div>
              )}

              {notification.type === "registration" && (
                <div>
                  <img src={alarm} alt="" />
                  <p>
                    Registracija ističe:{" "}
                    {notification.data.againRegistrationDate} <br />
                    {notification.data.carName}
                  </p>
                </div>
              )}
              {notification.type === "serviceMileage" && (
                <div>
                  <img
                    src={
                      +notification.data.highestMileage >
                      +notification.data.mileageNumber
                        ? Math.abs(
                            +notification.data.mileageNumber -
                              +notification.data.highestMileage +
                              +notification.data.nextServiceMileage
                          ) >= +notification.data.alarm
                          ? ring
                          : alarm
                        : +notification.data.mileageNumber -
                            +notification.data.highestMileage >=
                          +notification.data.alarm
                        ? ring
                        : alarm
                    }
                    alt=""
                  />
                  <p>
                    Ponovni servis: {notification.data.serviceName} <br />
                    za:
                    {+notification.data.highestMileage >
                    +notification.data.mileageNumber ? (
                      <span>
                        {Math.abs(
                          +notification.data.mileageNumber -
                            +notification.data.highestMileage +
                            +notification.data.nextServiceMileage
                        )}{" "}
                        km
                      </span>
                    ) : (
                      <span>
                        {+notification.data.mileageNumber -
                          +notification.data.highestMileage}{" "}
                        km
                      </span>
                    )}
                    <br />
                    {/* {notification.data.highestMileage} */}
                    {/* {notification.data.highestMileage} */}
                    {notification.data.carName}
                  </p>
                </div>
              )}
              {notification.type === "warrantyMileage" && (
                <div>
                  <img
                    src={
                      +notification.data.warrantyMileageNumber -
                        +notification.data.highestMileage >=
                      +notification.data.alarm
                        ? ring
                        : alarm
                    }
                    alt=""
                  />
                  <p>
                    Garancija ističe za:{" "}
                    {+notification.data.warrantyMileageNumber -
                      +notification.data.highestMileage}{" "}
                    km <br />
                    {notification.data.carName}
                  </p>
                </div>
              )}
              {notification.type === "service" && (
                <div>
                  <img src={alarm} alt="" />
                  <p>
                    Sledeći servis za: {notification.data.serviceName} <br />
                    {notification.data.nextServiceDate} <br />
                    {notification.data.carName}
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>
      );
    }
  );

  return <div>{groupedNotificationElements}</div>;
};

export default NotificationBar;
