import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import { saveAs } from "file-saver";
import { createEvents } from "ics";
import "react-calendar/dist/Calendar.css";

import "./MyCalendar.css"; // Add styles for highlighted dates if needed

const MyCalendar = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState([]);
  console.log(events);
  const generateICSContent = () => {
    const calendarData = events.map((event) => {
      console.log(event);
      const formattedDate = event.date
        .toISOString()
        .replace(/[-:]/g, "")
        .replace(/\.\d+Z/, "");
      console.log(formattedDate);
      return `BEGIN:VEVENT
SUMMARY:${event.title || "Event Title"}
DTSTART:${formattedDate}
DTEND:${formattedDate}
DESCRIPTION:${event.content || ""}
END:VEVENT`;
    });

    const icsContent = `BEGIN:VCALENDAR
VERSION:2.0
CALSCALE:GREGORIAN
${calendarData.join("\r\n")}
END:VCALENDAR`;

    return icsContent;
  };

  const handleDownloadICS = () => {
    const icsContent = generateICSContent();
    saveAs(
      new Blob([icsContent], { type: "text/calendar;charset=utf-8" }),
      "events.ics"
    );
  };

  useEffect(() => {
    // Fetch events data from local storage
    const storedData = localStorage.getItem("allData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      const eventDates = [];

      parsedData.forEach((item) => {
        // Check if the item has a date property
        if (item.location) {
          eventDates.push({
            date: new Date(item.date),
            title: `Putni Nalog za: ${item.location}`,
            content: ``,
          });
        }
        if (item.nextServiceDate) {
          eventDates.push({
            date: new Date(item.nextServiceDate),
            title: `Ponoviti servisa: ${item.serviceName},`,
            content: `Auto: ${item.carName}`,
          });
        }
        if (item.serviceDate) {
          eventDates.push({
            date: new Date(item.serviceDate),
            title: `Servis: ${item.serviceName}`,
            content: `Auto: ${item.carName} `,
          });
        }
        if (item.policyExpiryDate) {
          eventDates.push({
            date: new Date(item.policyExpiryDate),
            title: `Istek polise`,
            content: `Auto: ${item.manufacturer}-${item.model}-${item.licensePlate}`,
          });
        }
        if (item.warrantyExpiryNumber) {
          eventDates.push({
            date: new Date(item.warrantyExpiryNumber),
            title: `Istek garancije`,
            content: ` Auto: ${item.manufacturer}-${item.model}-${item.licensePlate}`,
          });
        }
        if (item.registrationDate) {
          eventDates.push({
            date: new Date(item.registrationDate),
            title: `Registracija auta`,
            content: `Auto: ${item.manufacturer}-${item.model}-${item.licensePlate}`,
          });
        }
        if (item.fuelDate) {
          eventDates.push({
            date: new Date(item.fuelDate),
            title: "Registrovana kilometraža",
            content: `Auto: ${item.carName}, Kilometraža: ${item.mileage}`,
          });
        }
        // Add other date properties as needed
        // Example: registrationDate, warrantyExpiryNumber, policyExpiryDate, etc.
      });

      setEvents(eventDates);
    }
  }, []);

  const handleDateClick = (date) => {
    // Find events for the clicked date
    const eventsForDate = events.filter(
      (event) => event.date.toDateString() === date.toDateString()
    );

    // Set the selected date and its events in the state
    setSelectedDate(date);
    setSelectedEvents(eventsForDate);
  };

  return (
    <div className="calendar-flex">
      <div>
        <Calendar
          className="react-calendar"
          onChange={setSelectedDate}
          value={selectedDate}
          locale="en-US"
          onClickDay={(date) => handleDateClick(date)}
          tileContent={({ date, view }) => {
            // Check if the date has events and return content for the tile
            const hasEvents = events.some(
              (event) => event.date.toDateString() === date.toDateString()
            );
            return hasEvents ? <div className="highlighted-date"></div> : null;
          }}
        />
        <div>
          <button className="btn" onClick={handleDownloadICS}>
            Preuzmi kalendar
          </button>
        </div>
      </div>
      <div>
        <h3>Selektovani datum: {selectedDate.toLocaleDateString()}</h3>
        {selectedEvents.map((event, index) => (
          <div className="date-info-box">
            <h4 key={index}>{event.title}</h4>
            <p key={index}>{event.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyCalendar;
