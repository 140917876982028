import React, { useState, useEffect } from "react";
import {
  getFirestore,
  doc,
  setDoc,
  collection,
  collectionGroup,
  getDocs,
} from "firebase/firestore";
import { app } from "../../firebase/firebase";
import FormSubmitted from "./FormSubmitted";
import "./form.css";

import { useAuth } from "../../firebase/useAuth";

const YourVozilaForm = ({ toggleEnable }) => {
  const { userEmail } = useAuth();
  const db = getFirestore(app);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [carCount, setCarCount] = useState(0);
  const [error, setError] = useState(false);

  function generateOrdinalNumber(index) {
    // Pad the index with leading zeros to make it 2 digits
    return index.toString().padStart(2, "0");
  }

  useEffect(() => {
    // Fetch the list of cars when the component mounts
    const fetchCars = async () => {
      const carsCollection = collectionGroup(db, "info");

      try {
        const querySnapshot = await getDocs(carsCollection);
        console.log(querySnapshot);
        const carList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log(carList);
        setCarCount(carList.length);
      } catch (error) {
        console.error("Error fetching cars: ", error);
      }
    };

    fetchCars();
  }, [db]);
  // Example usage:

  const fieldTypes = {
    year: "number",
    vin: "number",
    enginePower: "number",
    registrationDate: "date",
    lastInspectionDate: "date",
    mileage: "number",
    numberOfDoors: "number",
    numberOfSeats: "number",
    engineNumber: "number",
    policyNumber: "number",
    policyExpiryDate: "date",
    warrantyNumber: "number",
    warrantyMileageNumber: "number",
    warrantyExpiryNumber: "date",
  };

  // Define an object to hold form data with default values
  const [formData, setFormData] = useState({
    manufacturer: "",
    model: "",
    year: "",
    vin: "",
    licensePlate: "",
    enginePower: "",
    registrationDate: "",
    lastInspectionDate: "",
    mileage: "",
    fuelType: "",
    numberOfDoors: "",
    numberOfSeats: "",
    transmissionType: "",
    color: "",
    engineNumber: "",
    ownershipStatus: "",
    insuranceCompany: "",
    policyNumber: "",
    policyExpiryDate: "",
    supplierCompany: "",
    warrantyNumber: "",
    warrantyMileageNumber: "",
    warrantyExpiryNumber: "",
    selectedDriver: "",
  });

  const translateLabel = (label) => {
    const translations = {
      manufacturer: "Proizvođač",
      model: "Model",
      year: "Godina",
      vin: "Broj Šasije",
      licensePlate: "Registarski broj",
      enginePower: "Snaga motora",
      registrationDate: "Datum registracije",
      lastInspectionDate: "Datum poslednjeg pregleda",
      mileage: "Kilometraža",
      fuelType: "Tip goriva",
      numberOfDoors: "Broj vrata",
      numberOfSeats: "Broj sjedala",
      transmissionType: "Tip prenosa",
      color: "Boja",
      engineNumber: "Broj motora",
      ownershipStatus: "Status vlasništva",
      insuranceInfo: "Podaci o osiguranju",
      serviceHistory: "Istorija servisa",
      selectedDriver: "Izabrani vozač",
      insuranceCompany: "Osiguravajuća kuća",
      policyNumber: "Broj polise",
      policyExpiryDate: "Datum isteka polise",
      supplierCompany: "Dobavljačka firma",
      warrantyNumber: "Broj garancije",
      warrantyMileageNumber: "Garancije kilometraže",
      warrantyExpiryNumber: "Datum isteka garancije",
    };

    return translations[label] || label;
  };

  // State for storing the list of drivers from Firebase
  const [drivers, setDrivers] = useState([]);

  function generateRandom8DigitNumber() {
    const min = 10000000; // Smallest 8-digit number (10^7)
    const max = 99999999; // Largest 8-digit number (10^8 - 1)

    // Generate a random number between min and max
    const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;

    return randomNum;
  }

  useEffect(() => {
    // Fetch the list of drivers when the component mounts
    const fetchDrivers = async () => {
      const driversCollection = collection(db, "drivers");

      try {
        const driverQuerySnapshot = await getDocs(driversCollection);
        const driverList = driverQuerySnapshot.docs.map((driverDoc) => ({
          id: driverDoc.id,
          ...driverDoc.data(),
        }));
        setDrivers(driverList);
      } catch (error) {
        console.error("Error fetching drivers: ", error);
      }
    };

    fetchDrivers();
  }, [db]);

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if any required field is empty
    const requiredFields = [
      "manufacturer",
      "model",
      "year",
      "vin",
      "licensePlate",
      "enginePower",
      "registrationDate",
      "lastInspectionDate",
      "mileage",
      "fuelType",
      "numberOfDoors",
      "numberOfSeats",
      "transmissionType",
      "color",
      "engineNumber",
      "ownershipStatus",
      "insuranceCompany",
      "policyNumber",
      "policyExpiryDate",
      "supplierCompany",
      "warrantyNumber",
      "warrantyMileageNumber",
      "warrantyExpiryNumber",
      "selectedDriver",
    ];

    const emptyFields = requiredFields.filter((field) => !formData[field]);

    if (emptyFields.length > 0) {
      console.error("All fields are required.");
      setError(true);
      // Display a message to the user, for example, set an error state
      return;
    }

    // Generate a unique ID for the car
    const nextCarCount = carCount + 1;
    const carId = generateOrdinalNumber(nextCarCount);

    // const registrationDate = new Date(formData.registrationDate);
    // registrationDate.setFullYear(registrationDate.getFullYear() + 1);

    // Create an object with form data and selected driver
    const carData = {
      id: carId,
      user: userEmail,
      // againRegistrationDate: registrationDate.toISOString().split("T")[0],
      ...formData,
    };

    const carDocRef = doc(
      db,
      "cars",
      `${carData.manufacturer}-${carData.model}-${carData.licensePlate}`,
      "info",
      `car`
    );
    const today = new Date();
    const formattedDate = today.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    // const todayString = today.toISOString();

    const fuelData = {
      fuelId: generateRandom8DigitNumber(),
      carId,
      carName: `${carData.manufacturer}-${carData.model}-${carData.licensePlate}`,
      mileage: carData.mileage,
      fuelDate: formattedDate,
      user: userEmail,
    };

    const fuelDocRef = doc(
      db,
      "cars",
      `${carData.manufacturer}-${carData.model}-${carData.licensePlate}`,
      "milage",
      `${fuelData.fuelId}`
    );
    const warrantyMileageData = {
      id: generateRandom8DigitNumber(),
      carName: `${carData.manufacturer}-${carData.model}-${carData.licensePlate}`,
      startMileage: carData.mileage,
      warrantyMileageNumber: carData.warrantyMileageNumber,
      type: "warrantyMileage",
    };
    const warrantyMileageRef = doc(
      db,
      "cars",
      `${carData.manufacturer}-${carData.model}-${carData.licensePlate}`,
      "notificationService",
      `${warrantyMileageData.id}`
    );
    try {
      // Set the data with the specified document ID (licensePlate)
      await setDoc(carDocRef, carData);
      console.log("Document successfully written!");
      await setDoc(fuelDocRef, fuelData);
      await setDoc(warrantyMileageRef, warrantyMileageData);
      // Reset the form data object
      setFormData({
        ...formData,
        selectedDriver: "", // Reset the selected driver
      });
      setFormSubmitted(true);
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  function onClose() {
    setFormSubmitted(false);
  }
  return (
    <div className="custom-form-container">
      <h2 style={{ paddingBottom: "20px" }}>Dodajte vozilo</h2>
      <form onSubmit={handleSubmit} className="custom-form">
        {Object.keys(formData).map((key) => (
          <div key={key} className="custom-form-group">
            <label htmlFor={key} className="custom-label">
              {translateLabel(key)}:
            </label>
            <input
              type={fieldTypes[key] || "text"} // Default to "text" if type is not specified
              name={key}
              value={formData[key]}
              onChange={handleChange}
              className="custom-input"
            />
          </div>
        ))}
        <div className="custom-form-group">
          <label htmlFor="" className="custom-label">
            Vozač:
          </label>
          <select
            name="selectedDriver"
            value={formData.selectedDriver}
            onChange={handleChange}
            className="custom-select"
          >
            <option value="">Izaberi vozača</option>
            {drivers.map((driver) => (
              <option key={driver.id} value={driver.id}>
                {driver.driverName}
              </option>
            ))}
          </select>
          {error && (
            <div className="error-message">
              Sva polja moraju biti popunjena!
            </div>
          )}
          <div>
            <button className="custom-submit-button" type="submit">
              Prihvati
            </button>
            <button
              className="deleteBtn"
              style={{ borderRadius: "5px", marginLeft: "10px" }}
              onClick={toggleEnable}
              type="button"
            >
              Odustani
            </button>
          </div>
        </div>
        {formSubmitted && <FormSubmitted onClose={onClose} />}
      </form>
    </div>
  );
};

export default YourVozilaForm;
