import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { updateDoc, doc } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import "../form.css";

const UpdateUserProfileForm = ({ initialData, onClose }) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const [driverName, setDriverName] = useState(initialData.driverName);
  const [email, setEmail] = useState(initialData.email);
  const [phone, setPhone] = useState(initialData.phone);
  const [licenseInfo, setLicenseInfo] = useState(initialData.licenseInfo);

  const isFormValid = () => {
    return driverName && email && phone && licenseInfo;
  };

  useEffect(() => {
    setDriverName(initialData.driverName);
    setEmail(initialData.email);
    setPhone(initialData.phone);
    setLicenseInfo(initialData.licenseInfo);
  }, [initialData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      setError("All fields are required.");
      return;
    }

    const userProfileData = {
      driverName,
      email,
      phone,
      licenseInfo,
    };

    const userProfileDocRef = doc(db, "drivers", initialData.id);

    try {
      // Update the user profile data in Firestore
      await updateDoc(userProfileDocRef, userProfileData);
      console.log("User profile document successfully updated!");
      setFormSubmitted(true);
      setError(null);

      // Close the form
      window.location.reload();
      onClose();
    } catch (error) {
      console.error("Error updating user profile document:", error.message);
      setError("Error updating user profile. Please try again.");
    }
  };

  const modalContent = (
    <div className="custom-form-container modal-edit">
      <h2>Uredi vozača</h2>
      <form className="custom-form" onSubmit={handleSubmit}>
        <div className="custom-form-group">
          <label className="custom-label">Ime vozača:</label>
          <input
            className="custom-input"
            type="text"
            value={driverName}
            onChange={(e) => setDriverName(e.target.value)}
          />
        </div>
        <div className="custom-form-group">
          <label className="custom-label">Email:</label>
          <input
            className="custom-input"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="custom-form-group">
          <label className="custom-label">Telefon:</label>
          <input
            className="custom-input"
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <div className="custom-form-group">
          <label className="custom-label">Informacije o licenci:</label>
          <input
            className="custom-input"
            type="text"
            value={licenseInfo}
            onChange={(e) => setLicenseInfo(e.target.value)}
          />
        </div>
        <div>
          <button className="custom-submit-button" type="submit">
            Ažuriraj
          </button>
          <button
            style={{ borderRadius: "5px", marginLeft: "10px" }}
            className="deleteBtn"
            onClick={onClose}
          >
            Odustani
          </button>
        </div>

        {/* Display Form Submitted Message */}
        {/* {formSubmitted && <FormSubmitted onClose={onClose} />} */}
      </form>
    </div>
  );

  const appContainer = document.querySelector(".App");

  if (!appContainer) {
    console.error("Target container not found");
    return null;
  }

  // Use ReactDOM.createPortal after checking the target container
  return ReactDOM.createPortal(modalContent, appContainer);
};

export default UpdateUserProfileForm;
