import React, { useState } from "react";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { app } from "../../firebase/firebase";
import FormSubmitted from "./FormSubmitted";
import { useAuth } from "../../firebase/useAuth";

const UserProfileForm = ({ toggleEnable }) => {
  const { userEmail } = useAuth();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const db = getFirestore(app);

  const [driverName, setDriverName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [licenseInfo, setLicenseInfo] = useState("");

  const isFormValid = () => {
    return driverName && email && phone && licenseInfo;
  };
  function generateRandom8DigitNumber() {
    const min = 10000000; // Smallest 8-digit number (10^7)
    const max = 99999999; // Largest 8-digit number (10^8 - 1)

    // Generate a random number between min and max
    const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;

    return randomNum;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formSubmitted) return;
    console.log("handleSubmit called");
    if (!isFormValid()) {
      setError("All fields are required.");
      return;
    }

    const userProfileData = {
      id: generateRandom8DigitNumber().toString(),
      driverName,
      email,
      phone,
      licenseInfo,
      user: userEmail,
    };

    const userProfileDocRef = doc(db, "drivers", userProfileData.id);

    try {
      await setDoc(userProfileDocRef, userProfileData);
      console.log("User profile successfully updated!");
      setFormSubmitted(true);
      setError(null);
    } catch (error) {
      console.error("Error updating user profile: ", error);
      setError("Error updating user profile. Please try again.");
    }
  };

  function onClose() {
    setFormSubmitted(false);
  }
  return (
    <div className="custom-form-container">
      <h2>Dodajte vozača</h2>
      <form className="custom-form" onSubmit={handleSubmit}>
        <div className="custom-form-group">
          <label className="custom-label">Ime vozača:</label>
          <input
            className="custom-input"
            type="text"
            value={driverName}
            onChange={(e) => setDriverName(e.target.value)}
          />
        </div>
        <div className="custom-form-group">
          <label className="custom-label">Email:</label>
          <input
            className="custom-input"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="custom-form-group">
          <label className="custom-label">Telefon:</label>
          <input
            className="custom-input"
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <div className="custom-form-group">
          <label className="custom-label">Informacije o licenci:</label>
          <input
            className="custom-input"
            type="text"
            value={licenseInfo}
            onChange={(e) => setLicenseInfo(e.target.value)}
          />
        </div>
        <div>
          <button className="custom-submit-button" type="submit">
            Prihvati
          </button>
          <button
            className="deleteBtn"
            style={{ borderRadius: "5px", marginLeft: "10px" }}
            onClick={toggleEnable}
            type="button"
          >
            Odustani
          </button>
        </div>
        {error && (
          <div className="error-message">Sva polja se moraju popuniti</div>
        )}
        {formSubmitted && <FormSubmitted onClose={onClose} />}
      </form>
    </div>
  );
};

export default UserProfileForm;
