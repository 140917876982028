import React, { useState, useEffect } from "react";
import "./FilteringComponent.css";

import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase/firebase";

import options from "../../assets/options.png";

const FilteringComponent = ({
  drivers = false,
  carSelection = true,
  search = true,
  dates = true,
  onFilterChange,
  cars,
}) => {
  const [selectedCar, setSelectedCar] = useState("");
  const [selectedDriver, setSelectedDriver] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [nextServiceStartDate, setNextServiceStartDate] = useState("");
  const [nextServiceEndDate, setNextServiceEndDate] = useState("");
  const [driversList, setDriversList] = useState([]);

  useEffect(() => {
    if (drivers) {
      // Fetch the list of drivers when the component mounts
      const fetchDrivers = async () => {
        const driversCollection = collection(db, "drivers");

        try {
          const driverQuerySnapshot = await getDocs(driversCollection);
          const driverList = driverQuerySnapshot.docs.map((driverDoc) => ({
            id: driverDoc.id,
            ...driverDoc.data(),
          }));
          setDriversList(driverList);
        } catch (error) {
          console.error("Error fetching drivers: ", error);
        }
      };

      fetchDrivers();
    }
  }, [db, drivers]);

  const handleCarChange = (e) => {
    const newSelectedCar = e.target.value;
    setSelectedCar(newSelectedCar);
    onFilterChange({
      selectedCar: newSelectedCar,
      selectedDriver,
      searchQuery,
      sortBy,
      startDate,
      endDate,
      nextServiceStartDate,
      nextServiceEndDate,
    });
  };

  const handleDriverChange = (e) => {
    const newSelectedDriver = e.target.value;
    setSelectedDriver(newSelectedDriver);
    onFilterChange({
      selectedCar,
      selectedDriver: newSelectedDriver,
      searchQuery,
      sortBy,
      startDate,
      endDate,
      nextServiceStartDate,
      nextServiceEndDate,
    });
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    onFilterChange({
      selectedCar,
      searchQuery: e.target.value,
      sortBy,
      startDate,
      endDate,
      nextServiceStartDate,
      nextServiceEndDate,
    });
  };

  const handleSortChange = (e) => {
    setSortBy(e.target.value);
    onFilterChange({
      selectedCar,
      searchQuery,
      sortBy: e.target.value,
      startDate,
      endDate,
      nextServiceStartDate,
      nextServiceEndDate,
    });
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    onFilterChange({
      selectedCar,
      searchQuery,
      sortBy,
      startDate: e.target.value,
      endDate,
      nextServiceStartDate,
      nextServiceEndDate,
    });
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
    onFilterChange({
      selectedCar,
      searchQuery,
      sortBy,
      startDate,
      endDate: e.target.value,
      nextServiceStartDate,
      nextServiceEndDate,
    });
  };

  const handleNextServiceStartDateChange = (e) => {
    setNextServiceStartDate(e.target.value);
    onFilterChange({
      selectedCar,
      searchQuery,
      sortBy,
      startDate,
      endDate,
      nextServiceStartDate: e.target.value,
      nextServiceEndDate,
    });
  };

  const handleNextServiceEndDateChange = (e) => {
    setNextServiceEndDate(e.target.value);
    onFilterChange({
      selectedCar,
      searchQuery,
      sortBy,
      startDate,
      endDate,
      nextServiceStartDate,
      nextServiceEndDate: e.target.value,
    });
  };

  const handleClearFilters = () => {
    setSelectedCar("");
    setSearchQuery("");
    setSortBy("");
    setStartDate("");
    setEndDate("");
    setNextServiceStartDate("");
    setNextServiceEndDate("");
    onFilterChange({
      selectedCar: "",
      searchQuery: "",
      sortBy: "",
      startDate: "",
      endDate: "",
      nextServiceStartDate: "",
      nextServiceEndDate: "",
    });
  };

  return (
    <div className="filter-container">
      {carSelection && (
        <select
          className="filter-select"
          value={selectedCar}
          onChange={handleCarChange}
        >
          <option value="">Selektuj auto...</option>
          {cars.map((car) => (
            <option key={car.id} value={car.id}>
              {car.manufacturer} - {car.model} - {car.licensePlate}
            </option>
          ))}
        </select>
      )}
      {drivers && (
        <select
          className="filter-select"
          value={selectedDriver}
          onChange={handleDriverChange}
        >
          <option value="">Selektuj vozača...</option>
          {driversList.map((driver) => (
            <option key={driver.id} value={driver.id}>
              {driver.driverName}
            </option>
          ))}
        </select>
      )}
      {search && (
        <input
          type="text"
          className="filter-input"
          placeholder="Pretraži..."
          value={searchQuery}
          onChange={handleSearchChange}
        />
      )}
      <div className="dates">
        <label>
          Datum od:
          <input
            type="date"
            className="date-input"
            value={startDate}
            onChange={handleStartDateChange}
          />
        </label>
        <label>
          Datum do:
          <input
            type="date"
            className="date-input"
            value={endDate}
            onChange={handleEndDateChange}
          />
        </label>
      </div>
      {dates && (
        <div className="dates">
          <label>
            Sl. servis od:
            <input
              type="date"
              className="date-input"
              value={nextServiceStartDate}
              onChange={handleNextServiceStartDateChange}
            />
          </label>
          <label>
            Sl. servis do:
            <input
              type="date"
              className="date-input"
              value={nextServiceEndDate}
              onChange={handleNextServiceEndDateChange}
            />
          </label>
        </div>
      )}

      <button
        className="clear-filters-button deleteBtn"
        onClick={handleClearFilters}
      >
        Očisti Filtere
      </button>
      <div className="second-row">
        <select
          className="filter-select"
          value={sortBy}
          onChange={handleSortChange}
        >
          <option value="">Sortiraj po...</option>
          <option value="serviceDate">Datumu</option>
          {/* Add more sorting options based on your data */}
        </select>
      </div>
    </div>
  );
};

export default FilteringComponent;
