import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { updateDoc, doc } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import FormSubmitted from "../FormSubmitted";
import "../form.css";
const UpdateServiceForm = ({ initialData, onClose }) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [serviceName, setServiceName] = useState(initialData.serviceName);
  const [serviceCost, setServiceCost] = useState(initialData.serviceCost);
  const [serviceDate, setServiceDate] = useState(initialData.serviceDate);
  const [needsServicing, setNeedsServicing] = useState(
    initialData.needsServicing
  );
  const [nextServiceDate, setNextServiceDate] = useState(
    initialData.nextServiceDate || ""
  );

  // Add new states for the missing inputs
  // const [nextServiceMileage, setNextServiceMileage] = useState(
  //   initialData.nextServiceMileage || ""
  // );
  // const [mileage, setMileage] = useState(initialData.mileage || "");

  const [emptyFields, setEmptyFields] = useState({
    serviceName: false,
    serviceCost: false,
    serviceDate: false,
    nextServiceDate: false,
    // Add new flags for the missing inputs
    // nextServiceMileage: false,
    mileage: false,
  });

  useEffect(() => {
    setServiceName(initialData.serviceName);
    setServiceCost(initialData.serviceCost);
    setServiceDate(initialData.serviceDate);
    setNeedsServicing(initialData.needsServicing);
    setNextServiceDate(initialData.nextServiceDate || "");
    // Update the states for the missing inputs
    // setNextServiceMileage(initialData.nextServiceMileage || "");
  }, [initialData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emptyFieldsCopy = { ...emptyFields };
    if (!serviceName) emptyFieldsCopy.serviceName = true;
    if (!serviceCost) emptyFieldsCopy.serviceCost = true;
    if (!serviceDate) emptyFieldsCopy.serviceDate = true;
    if (needsServicing && !nextServiceDate) {
      emptyFieldsCopy.nextServiceDate = true;
    }
    // Add checks for the missing inputs
    // if (needsServicing && !nextServiceMileage) {
    //   emptyFieldsCopy.nextServiceMileage = true;
    // }

    // Update the state with the new emptyFields values
    setEmptyFields(emptyFieldsCopy);

    if (
      !serviceName ||
      !serviceCost ||
      !serviceDate ||
      (needsServicing && !nextServiceDate)
      // (needsServicing && !nextServiceMileage)
    ) {
      console.error("All fields are required.");
      return;
    }

    const serviceData = {
      serviceName,
      serviceCost,
      serviceDate,
      needsServicing,
      nextServiceDate: needsServicing ? nextServiceDate : null,
      // Add new fields for the missing inputs
      // nextServiceMileage,
    };

    const serviceDocRef = doc(
      db,
      "cars",
      `${initialData.carName}`,
      "services",
      `${initialData.serviceId}`
    );

    try {
      // Update the service data in Firestore
      await updateDoc(serviceDocRef, serviceData);
      console.log("Service document successfully updated!");
      setFormSubmitted(true);

      // Close the form
      onClose();
    } catch (error) {
      console.error("Error updating service document:", error.message);
    }
  };

  const modalContent = (
    <div className="custom-form-container  modal-edit">
      <h2 style={{ paddingBottom: "20px" }}>Uredi servis</h2>
      <form className="custom-form" onSubmit={handleSubmit}>
        {/* Service Name Input */}
        <div
          className={`custom-form-group ${emptyFields.serviceName && "error"}`}
        >
          <label className="custom-label">Ime usluge:</label>
          <input
            className={`custom-input ${emptyFields.serviceName && "error"}`}
            type="text"
            value={serviceName}
            onChange={(e) => setServiceName(e.target.value)}
          />
          {emptyFields.serviceName && (
            <div className="error-message">Ime usluge je obavezno</div>
          )}
        </div>

        {/* Service Cost Input */}
        <div
          className={`custom-form-group ${emptyFields.serviceCost && "error"}`}
        >
          <label className="custom-label">Troškovi usluge:</label>
          <input
            className={`custom-input ${emptyFields.serviceCost && "error"}`}
            type="number"
            value={serviceCost}
            onChange={(e) => setServiceCost(e.target.value)}
          />
          {emptyFields.serviceCost && (
            <div className="error-message">Trošak usluge je obavezan</div>
          )}
        </div>

        {/* Service Date Input */}
        <div
          className={`custom-form-group ${emptyFields.serviceDate && "error"}`}
        >
          <label className="custom-label">Datum servisa:</label>
          <input
            className={`custom-input ${emptyFields.serviceDate && "error"}`}
            type="date"
            value={serviceDate}
            onChange={(e) => setServiceDate(e.target.value)}
          />
          {emptyFields.serviceDate && (
            <div className="error-message">Datum servisa je obavezan</div>
          )}
        </div>

        {/* Needs Servicing Checkbox */}
        <div className="custom-form-group">
          <label className="custom-label">
            Treba ponovni servis:
            <input
              type="checkbox"
              checked={needsServicing}
              onChange={() => setNeedsServicing(!needsServicing)}
            />
          </label>
        </div>

        {/* Next Service Date Input */}
        {needsServicing && (
          <div
            className={`custom-form-group ${
              emptyFields.nextServiceDate && "error"
            }`}
          >
            <label className="custom-label">Datum sledećeg servisa:</label>
            <input
              className={`custom-input ${
                emptyFields.nextServiceDate && "error"
              }`}
              type="date"
              value={nextServiceDate}
              onChange={(e) => setNextServiceDate(e.target.value)}
            />
            {emptyFields.nextServiceDate && (
              <div className="error-message">
                Datum sledećeg servisa je obavezan
              </div>
            )}
          </div>
        )}
        {/* Next Service Mileage Input */}
        {/* {initialData.needsMileageServicing && (
          <div
            className={`custom-form-group ${
              emptyFields.nextServiceMileage && "error"
            }`}
          >
            <label className="custom-label">
              Kilometraža za sledeći servis:
            </label>
            <input
              className={`custom-input ${
                emptyFields.nextServiceMileage && "error"
              }`}
              type="number"
              value={nextServiceMileage}
              onChange={(e) => setNextServiceMileage(e.target.value)}
            />
            {emptyFields.nextServiceMileage && (
              <div className="error-message">
                Unesite kilometražu za sledeći servis
              </div>
            )}
          </div>
        )} */}

        {/* Submit Button */}
        <div>
          <button className="custom-submit-button" type="submit">
            Ažuriraj
          </button>
          <button
            style={{ borderRadius: "5px", marginLeft: "10px" }}
            className="deleteBtn"
            onClick={onClose}
          >
            Odustani
          </button>
        </div>

        {/* Display Form Submitted Message */}
        {/* {formSubmitted && <FormSubmitted onClose={onClose} />} */}
      </form>
    </div>
  );
  const appContainer = document.querySelector(".App");

  if (!appContainer) {
    console.error("Target container not found");
    return null;
  }

  // Use ReactDOM.createPortal after checking the target container
  return ReactDOM.createPortal(modalContent, appContainer);
};

export default UpdateServiceForm;
