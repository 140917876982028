import React, { useState, useEffect } from "react";
import {
  addDoc,
  collection,
  updateDoc,
  doc,
  setDoc,
  collectionGroup,
} from "firebase/firestore";
import { getFirestore, query, orderBy, getDocs } from "firebase/firestore";
import { app } from "../../firebase/firebase";
import FormSubmitted from "./FormSubmitted";

import { useAuth } from "../../firebase/useAuth";

const ServiceForm = ({ toggleEnable }) => {
  const { userEmail } = useAuth();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const db = getFirestore(app);

  const [cars, setCars] = useState([]);
  const [selectedCar, setSelectedCar] = useState({ id: "", name: "" });
  const [serviceName, setServiceName] = useState("");
  const [serviceCost, setServiceCost] = useState("");
  const [serviceDate, setServiceDate] = useState("");
  const [needsServicing, setNeedsServicing] = useState(false);
  const [needsMileageServicing, setNeedsMileageServicing] = useState(false);
  const [nextServiceDate, setNextServiceDate] = useState("");
  const [nextServiceMileage, setnextServiceMileage] = useState("");
  const [mileage, setMileage] = useState("");

  const [emptyFields, setEmptyFields] = useState({
    selectedCar: false,
    serviceName: false,
    serviceCost: false,
    serviceDate: false,
    nextServiceDate: false,
    nextServiceMileage: false,
    mileage: false,
  });

  function generateRandom8DigitNumber() {
    const min = 10000000; // Smallest 8-digit number (10^7)
    const max = 99999999; // Largest 8-digit number (10^8 - 1)

    // Generate a random number between min and max
    const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;

    return randomNum;
  }
  const [mileageData, setMileageDate] = useState();
  useEffect(() => {
    // Fetch new mileage data when the selected car changes
    let isMounted = true;

    const fetchData = async () => {
      try {
        const carsCollection = collectionGroup(db, "milage");
        const carQuerySnapshot = await getDocs(carsCollection);
        const carList = carQuerySnapshot.docs.map((carDoc) => ({
          id: carDoc.id,
          ...carDoc.data(),
        }));

        if (isMounted) {
          setMileageDate(carList);
        }

        // If selectedCar.id is truthy, filter the data
        if (selectedCar.id) {
          const filteredData = carList.filter(
            (item) => item.carId === selectedCar.id
          );

          if (filteredData.length > 0) {
            const highestMileageEntry = filteredData.reduce((max, entry) =>
              entry.mileage > max.mileage ? entry : max
            );

            if (isMounted) {
              setMileageDate(highestMileageEntry);
            }
          } else {
            // If no mileage data is found for the selected car, reset the state
            if (isMounted) {
              setMileageDate("");
            }
          }
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();

    // Cleanup function to handle component unmounting
    return () => {
      isMounted = false;
    };
  }, [selectedCar.id, db]);

  useEffect(() => {
    // Fetch the list of cars when the component mounts
    const fetchCars = async () => {
      const carsCollection = collectionGroup(db, "info");

      try {
        const querySnapshot = await getDocs(carsCollection);
        console.log(querySnapshot);
        const carList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log(carList);
        setCars(carList);
      } catch (error) {
        console.error("Error fetching cars: ", error);
      }
    };

    fetchCars();
  }, [db]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emptyFieldsCopy = { ...emptyFields };
    if (!selectedCar.id) emptyFieldsCopy.selectedCar = true;
    if (!serviceName) emptyFieldsCopy.serviceName = true;
    if (!serviceCost) emptyFieldsCopy.serviceCost = true;
    if (!serviceDate) emptyFieldsCopy.serviceDate = true;
    if (needsServicing && !nextServiceDate) {
      emptyFieldsCopy.nextServiceDate = true;
    }

    // Update the state with the new emptyFields values
    setEmptyFields(emptyFieldsCopy);

    if (
      !selectedCar ||
      !serviceName ||
      !serviceCost ||
      !serviceDate ||
      (needsServicing && !nextServiceDate) ||
      (needsMileageServicing && !nextServiceMileage) ||
      (needsMileageServicing && !mileage)
    ) {
      console.error("All fields are required.");
      return;
    }

    const id = generateRandom8DigitNumber();

    const serviceData = {
      serviceId: id,
      carId: selectedCar.id,
      carName: selectedCar.name,
      serviceName,
      serviceCost,
      serviceDate,
      needsServicing,
      needsMileageServicing,
      nextServiceDate: needsServicing ? nextServiceDate : null,
      nextServiceMileage: needsMileageServicing ? nextServiceMileage : null,
      mileage: needsMileageServicing ? mileage : null,
      user: userEmail,
    };

    const serviceDocRef = doc(
      db,
      "cars",
      `${selectedCar.name}`,
      "services",
      `${serviceData.serviceId}`
    );
    const fuelData = {
      fuelId: generateRandom8DigitNumber(),
      carId: selectedCar.id,
      carName: selectedCar.name,
      mileage,
      fuelDate: serviceDate,
      user: userEmail,
    };

    const fuelDocRef = doc(
      db,
      "cars",
      `${selectedCar.name}`,
      "milage",
      `${fuelData.fuelId}`
    );

    const nextServiceData = {
      fuelId: generateRandom8DigitNumber(),
      carName: selectedCar.name,
      serviceName,
      nextServiceMileage,
      mileage,
      type: "serviceMileage",
    };
    const nextServiceRef = doc(
      db,
      "cars",
      `${selectedCar.name}`,
      "notificationService",
      `${nextServiceData.fuelId}`
    );

    try {
      // Add the service data to Firestore
      await setDoc(serviceDocRef, serviceData);
      console.log("Service document successfully written!");
      if (nextServiceDate) {
        await setDoc(fuelDocRef, fuelData);
      }
      if (needsMileageServicing) {
        await setDoc(nextServiceRef, nextServiceData);
      }
      setFormSubmitted(true);

      // Reset form fields
      setSelectedCar("");
      setServiceName("");
      setServiceCost("");
      setServiceDate("");
      setNeedsServicing(false);
      setNextServiceDate("");
    } catch (error) {
      console.error(
        "Error writing service document or updating car document: ",
        error
      );
    }
  };
  function onClose() {
    setFormSubmitted(false);
  }
  const isInvalidMileage =
    mileage !== "" && parseInt(mileage, 10) < mileageData.mileage;
  return (
    <div className="custom-form-container">
      <h2 style={{ paddingBottom: "20px" }}>Dodajte servis</h2>
      <form className="custom-form" onSubmit={handleSubmit}>
        {/* Select Car Dropdown */}
        <div
          className={`custom-form-group ${emptyFields.selectedCar && "error"}`}
        >
          <label className="custom-label">Izaberite automobil:</label>
          <select
            className={`custom-select ${emptyFields.selectedCar && "error"}`}
            value={selectedCar.id}
            onChange={(e) => {
              const selectedCarId = e.target.value;
              const selectedCarName =
                e.target.options[e.target.selectedIndex].text;

              setSelectedCar({
                id: selectedCarId,
                name: selectedCarName,
              });

              // Reset the error message when the user selects a car
              setEmptyFields((prev) => ({ ...prev, selectedCar: false }));
            }}
          >
            <option value="">Izaberite automobil</option>
            {cars.map((car) => (
              <option key={car.id} value={car.id}>
                {car.manufacturer}-{car.model}-{car.licensePlate}
              </option>
            ))}
          </select>
          {emptyFields.selectedCar && (
            <div className="error-message">Molimo izaberite automobil</div>
          )}
        </div>

        {/* Service Name Input */}
        <div
          className={`custom-form-group ${emptyFields.serviceName && "error"}`}
        >
          <label className="custom-label">Ime usluge:</label>
          <input
            className={`custom-input ${emptyFields.serviceName && "error"}`}
            type="text"
            value={serviceName}
            onChange={(e) => setServiceName(e.target.value)}
          />
          {emptyFields.serviceName && (
            <div className="error-message">Ime usluge je obavezno</div>
          )}
        </div>

        {/* Service Cost Input */}
        <div
          className={`custom-form-group ${emptyFields.serviceCost && "error"}`}
        >
          <label className="custom-label">Troškovi usluge: (KM)</label>
          <input
            className={`custom-input ${emptyFields.serviceCost && "error"}`}
            type="number"
            value={serviceCost}
            onChange={(e) => setServiceCost(e.target.value)}
          />
          {emptyFields.serviceCost && (
            <div className="error-message">Trošak usluge je obavezan</div>
          )}
        </div>

        {/* Service Date Input */}
        <div
          className={`custom-form-group ${emptyFields.serviceDate && "error"}`}
        >
          <label className="custom-label">Datum servisa:</label>
          <input
            className={`custom-input ${emptyFields.serviceDate && "error"}`}
            type="date"
            value={serviceDate}
            onChange={(e) => setServiceDate(e.target.value)}
          />
          {emptyFields.serviceDate && (
            <div className="error-message">Datum servisa je obavezan</div>
          )}
        </div>

        {/* Needs Servicing Checkbox */}
        <div className="custom-form-group">
          <label className="custom-label">
            Treba ponovni servis:
            <input
              type="checkbox"
              checked={needsServicing}
              onChange={() => setNeedsServicing(!needsServicing)}
            />
          </label>
        </div>

        {/* Next Service Date Input */}
        {needsServicing && (
          <div
            className={`custom-form-group ${
              emptyFields.nextServiceDate && "error"
            }`}
          >
            <label className="custom-label">Datum sledećeg servisa:</label>
            <input
              className={`custom-input ${
                emptyFields.nextServiceDate && "error"
              }`}
              type="date"
              value={nextServiceDate}
              onChange={(e) => setNextServiceDate(e.target.value)}
            />
            {emptyFields.nextServiceDate && (
              <div className="error-message">
                Datum sledećeg servisa je obavezan
              </div>
            )}
          </div>
        )}
        <div className="custom-form-group" style={{ padding: "10px 0px" }}>
          <label className="custom-label">
            Po kilometrima:
            <input
              type="checkbox"
              checked={needsMileageServicing}
              onChange={() => setNeedsMileageServicing(!needsMileageServicing)}
            />
          </label>
        </div>

        {needsMileageServicing && (
          <>
            {" "}
            <label className="custom-label">
              Servis treba ponoviti nakon: (km)
            </label>
            <input
              className={`custom-input ${
                emptyFields.nextServiceMileage && "error"
              }`}
              type="number"
              value={nextServiceMileage}
              onChange={(e) => setnextServiceMileage(e.target.value)}
            />
            <label className="custom-label">Stanje na kilometar satu: </label>
            <input
              className={`custom-input`}
              type="number"
              value={mileage}
              onChange={(e) => setMileage(e.target.value)}
            />
            {isInvalidMileage && (
              <div className="error-message">
                Molimo unesite kilometražu veću od posledjnje unesene
              </div>
            )}
            {mileageData && mileageData.mileage && (
              <div className="info-box" style={{ marginTop: "15px" }}>
                <p>
                  Poslednje stanje: {mileageData.mileage} km <br />
                  {mileageData.fuelDate}:{" "}
                </p>
              </div>
            )}
            {emptyFields.nextServiceDate && (
              <div className="error-message">Obavezno popuniti sva polja</div>
            )}
          </>
        )}
        {/* Submit Button */}
        <div>
          <button className="custom-submit-button" type="submit">
            Prihvati
          </button>
          <button
            className="deleteBtn"
            style={{ borderRadius: "5px", marginLeft: "10px" }}
            onClick={toggleEnable}
            type="button"
          >
            Odustani
          </button>
        </div>
        {/* Display Form Submitted Message */}
        {formSubmitted && <FormSubmitted onClose={onClose} />}
      </form>
    </div>
  );
};

export default ServiceForm;
//Vozni park republicki protokol
