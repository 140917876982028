import React, { useState } from "react";
import "./DataCardCar.css";
import {
  getDocs,
  collection,
  collectionGroup,
  query,
  getFirestore,
  where,
} from "firebase/firestore";
import { app } from "../../firebase/firebase";
import UpdateCarForm from "../FormComponents/UpdateForms/UpdateCarForm";

import CustomModal from "./CustomModal";
import DeleteButton from "../UIComponents/DeleteButton";
const DataCard = ({ dataItem }) => {
  const [expanded, setExpanded] = useState(false);
  const [edit, setEdit] = useState(false);

  const [modalData, setModalData] = useState(null);
  const [servicesData, setServicesData] = useState(null);
  const [fuelData, setFuelData] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const db = getFirestore(app);
  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  const fetchServicesData = async () => {
    const carId = dataItem.id; // Assuming dataItem has an 'id' property representing the car ID
    console.log(carId);
    // Assuming "services" is the collection name
    const servicesCollection = collectionGroup(db, "services");

    // Query for services where the carId field is equal to the selected car's ID
    const q = query(servicesCollection, where("carId", "==", carId));
    console.log("Firestore Query:", q);
    try {
      const servicesQuerySnapshot = await getDocs(q);
      const servicesList = servicesQuerySnapshot.docs.map((serviceDoc) => ({
        id: serviceDoc.id,
        ...serviceDoc.data(),
      }));
      console.log(servicesList);
      setModalData(servicesList);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error fetching services: ", error);
    }
  };

  const fetchFuelData = async () => {
    const carId = dataItem.id; // Assuming dataItem has an 'id' property representing the car ID
    // Assuming "services" is the collection name
    const servicesCollection = collectionGroup(db, "milage");

    // Query for services where the carId field is equal to the selected car's ID
    const q = query(servicesCollection, where("carId", "==", carId));
    try {
      const servicesQuerySnapshot = await getDocs(q);
      const servicesList = servicesQuerySnapshot.docs.map((serviceDoc) => ({
        id: serviceDoc.id,
        ...serviceDoc.data(),
      }));
      console.log(servicesList);
      setModalData(servicesList);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error fetching services: ", error);
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className={`data-card ${expanded ? "expanded" : ""}`}>
      <div className="data-card-header">
        <h3>{dataItem.manufacturer}</h3>
        <p>{dataItem.model}</p>
      </div>
      <div className="data-card-details">
        <div className="card-info">
          <div className="info-item">
            <span className="info-label">Godina:</span>
            <span className="info-value">{dataItem.year}</span>
          </div>
          <div className="info-item">
            <span className="info-label">Boja:</span>
            <span className="info-value">{dataItem.color}</span>
          </div>
          <div className="info-item">
            <span className="info-label">Broj motora:</span>
            <span className="info-value">{dataItem.engineNumber}</span>
          </div>
        </div>
        {expanded && (
          <div className="expanded-info">
            <div className="info-item">
              <span className="info-label">Jačina motora:</span>
              <span className="info-value">{dataItem.enginePower}</span>
            </div>
            <div className="info-item">
              <span className="info-label">Vrsta goriva:</span>
              <span className="info-value">{dataItem.fuelType}</span>
            </div>
            {/* <div className="info-item">
              <span className="info-label">Informacije o osiguranju:</span>
              <span className="info-value">{dataItem.insuranceInfo}</span>
            </div> */}
            <div className="info-item">
              <span className="info-label">Datum poslednje inspekcije:</span>
              <span className="info-value">{dataItem.lastInspectionDate}</span>
            </div>
            <div className="info-item">
              <span className="info-label">Registarske tablice:</span>
              <span className="info-value">{dataItem.licensePlate}</span>
            </div>
            <div className="info-item">
              <span className="info-label">Kilometraža:</span>
              <span className="info-value">{dataItem.mileage} km</span>
            </div>
            <div className="info-item">
              <span className="info-label">Broj vrata:</span>
              <span className="info-value">{dataItem.numberOfDoors}</span>
            </div>
            <div className="info-item">
              <span className="info-label">Broj sjedišta:</span>
              <span className="info-value">{dataItem.numberOfSeats}</span>
            </div>
            <div className="info-item">
              <span className="info-label">Status vlasništva:</span>
              <span className="info-value">{dataItem.ownershipStatus}</span>
            </div>
            <div className="info-item">
              <span className="info-label">Datum registracije:</span>
              <span className="info-value">{dataItem.registrationDate}</span>
            </div>
            {/* <div className="info-item">
              <span className="info-label">Servisna istorija:</span>
              <span className="info-value">{dataItem.serviceHistory}</span>
            </div> */}
            <div className="info-item">
              <span className="info-label">Tip prenosa:</span>
              <span className="info-value">{dataItem.transmissionType}</span>
            </div>
            <div className="info-item">
              <span className="info-label">VIN:</span>
              <span className="info-value">{dataItem.vin}</span>
            </div>
            <div className="info-item">
              <span className="info-label">Osiguravajuća kuća:</span>
              <span className="info-value">{dataItem.insuranceCompany}</span>
            </div>
            <div className="info-item">
              <span className="info-label">Broj polise:</span>
              <span className="info-value">{dataItem.policyNumber}</span>
            </div>
            <div className="info-item">
              <span className="info-label">Datum isteka polise:</span>
              <span className="info-value">{dataItem.policyExpiryDate}</span>
            </div>
            <div className="info-item">
              <span className="info-label">Dobavljačka firma:</span>
              <span className="info-value">{dataItem.supplierCompany}</span>
            </div>
            <div className="info-item">
              <span className="info-label">Broj garancije:</span>
              <span className="info-value">{dataItem.warrantyNumber}</span>
            </div>
            <div className="info-item">
              <span className="info-label">Datum isteka garancije:</span>
              <span className="info-value">
                {dataItem.warrantyExpiryNumber}
              </span>
            </div>
            <div className="info-item">
              <span className="info-label">
                Istek garancije po kilometrima:
              </span>
              <span className="info-value">
                {dataItem.warrantyMileageNumber} km
              </span>
            </div>
          </div>
        )}
        <div className="data-card-buttons">
          <button className="expand-button" onClick={fetchServicesData}>
            Servisi
          </button>
          <button className="expand-button" onClick={fetchFuelData}>
            Kilometraža
          </button>
        </div>
        <CustomModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          data={modalData}
        />
        <button className="expand-button" onClick={toggleExpansion}>
          {expanded ? "Prikaži Manje" : "Prikaži Više"}
        </button>
        <div style={{ display: "flex", gap: "10px" }}>
          <DeleteButton
            collectionName={"cars"}
            carName={`${dataItem.manufacturer}-${dataItem.model}-${dataItem.licensePlate}`}
            collectionGroupName={"info"}
            documentId={"car"}
          />
          <button className="btn" onClick={() => setEdit(true)}>
            Uredi
          </button>
          {edit && (
            <UpdateCarForm
              data={dataItem}
              onXClick={() => setEdit(false)}
              carName={`${dataItem.manufacturer}-${dataItem.model}-${dataItem.licensePlate}`}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DataCard;
