// Form.jsx
import React from "react";

import YourVozilaForm from "./VozilaForm";
import ServiceForm from "./ServiceForm";
import UserProfileForm from "./DriverForm";
import FuelForm from "./FuelForm";
import WarrantForm from "./WarrantForm";

import "../UIComponents/style.css";
// Define YourVozilaForm and YourServisiForm components here
// const YourVozilaForm = () => {
//   return <h1>Vozila</h1>;
// };

const Form = ({ selectedCategory, enable, toggleEnable }) => {
  let formContent = null;
  console.log(selectedCategory);

  // function toggleEnable
  switch (selectedCategory) {
    case "cars":
      formContent = <YourVozilaForm toggleEnable={toggleEnable} />;
      break;
    case "services":
      formContent = <ServiceForm toggleEnable={toggleEnable} />;
      break;
    case "drivers":
      formContent = <UserProfileForm toggleEnable={toggleEnable} />;
      break;
    case "fuel":
      formContent = <FuelForm toggleEnable={toggleEnable} />;
      break;
    case "warrants":
      formContent = <WarrantForm toggleEnable={toggleEnable} />;
      break;
    // Add more cases for other categories
    default:
      formContent = null;
  }

  return (
    <div className={`form-container ${enable ? "slide-out" : ""}`}>
      {/* <h1>Forma</h1> */}
      {formContent}
    </div>
  );
};

export default Form;
// zadnje stanje u datum kada se automobil izabere
