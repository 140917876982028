// App.js
import React, { useState } from "react";
import Header from "./Components/UIComponents/Header";
import Sidebar from "./Components/UIComponents/Sidebar";
import MainContent from "./Components/UIComponents/MainContent";
import PlusSign from "./Components/UIComponents/PlusSign";
import NotificationBar from "./Components/UIComponents/NotificationBar"; // Import the NotificationBar component

import "./Components/UIComponents/style.css";
import { useAuth } from "./firebase/useAuth";
import Login from "./firebase/Login";

function App() {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [notification, setNotification] = useState(null); // State for the notification message
  const { authenticated } = useAuth();
  const handleLinkClick = (category) => {
    setSelectedCategory(category);
    setIsSideMenuOpen(!isSideMenuOpen);
  };
  const handleStartClick = () => {
    setSelectedCategory(null);
  };
  // Function to display a notification message
  const showNotification = (message) => {
    setNotification(message);
    // Remove the notification after a certain time (e.g., 5 seconds)
    setTimeout(() => {
      setNotification(null);
    }, 5000);
  };
  // Display a notification when a button is clicked
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

  const handleMenuClick = () => {
    setIsSideMenuOpen(!isSideMenuOpen);
  };

  return (
    <div className="App">
      <Header
        handleClick={handleMenuClick}
        handleStartClick={handleStartClick}
      />
      <div className="container">
        <Sidebar
          onLinkClick={handleLinkClick}
          isOpen={isSideMenuOpen}
          handleClick={handleMenuClick}
        />

        {authenticated ? (
          <>
            {" "}
            <MainContent selectedCategory={selectedCategory} />
            {selectedCategory && selectedCategory !== "calendar" && (
              <PlusSign selectedCategory={selectedCategory} />
            )}{" "}
            {/* {!selectedCategory && <NotificationBar message={notification} />} */}
          </>
        ) : (
          <Login />
        )}

        {/* Render the NotificationBar component */}
      </div>
    </div>
  );
}

export default App;
