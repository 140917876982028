import React, { useState } from "react";
import {
  deleteDoc,
  doc,
  collection,
  getDocs,
  where,
  query,
  collectionGroup,
} from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { db } from "../../firebase/firebase";
// import { useAuth } from "./AuthContext"; // Import your authentication context

import "./DeleteButton.css";

const DeleteButton = ({
  collectionName,
  carName,
  collectionGroupName,
  documentId,
  serviceName,
  nextServiceMileage,
  nextServiceDate,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleDelete = async () => {
    try {
      if (!collectionGroupName && !documentId) {
        const docRef = doc(db, `${collectionName}`, `${carName}`);
        await deleteDoc(docRef);
        window.location.reload();
        return;
      }
      if (
        (collectionGroupName === "services" && nextServiceMileage) ||
        nextServiceDate
      ) {
        const carRef = doc(db, collectionName, carName);
        const notificationQuerySnapshot = await getDocs(
          collection(carRef, "notificationService")
        );

        notificationQuerySnapshot.forEach(async (doc) => {
          const docData = doc.data();

          // Check if the document matches the criteria
          if (
            nextServiceMileage &&
            docData.serviceName == serviceName &&
            docData.carName == carName &&
            docData.nextServiceMileage == nextServiceMileage
          ) {
            try {
              await deleteDoc(doc.ref);
              await deleteDoc(docRef);
              console.log("Document successfully deleted!");
              // Refresh the current page
              window.location.reload();
              console.log("Document deleted successfully!");
            } catch (error) {
              console.error("Error deleting document:", error);
            }
          }
          if (
            nextServiceDate &&
            docData.serviceName == serviceName &&
            docData.carName == carName &&
            docData.nextServiceDate == nextServiceDate
          ) {
            try {
              await deleteDoc(doc.ref);
              await deleteDoc(docRef);
              console.log("Document successfully deleted!");
              // Refresh the current page
              window.location.reload();
              console.log("Document deleted successfully!");
            } catch (error) {
              console.error("Error deleting document:", error);
            }
          }
        });
      }

      if (collectionGroupName === "info") {
        // If deleting the whole car with subcollections
        const carRef = doc(db, collectionName, carName);

        const servicesQuerySnapshot = await getDocs(
          collection(carRef, "services")
        );
        const milageQuerySnapshot = await getDocs(collection(carRef, "milage"));
        const notificationQuerySnapshot = await getDocs(
          collection(carRef, "notificationService")
        );

        // Delete each document in subcollections
        servicesQuerySnapshot.forEach(async (doc) => {
          await deleteDoc(doc.ref);
        });

        milageQuerySnapshot.forEach(async (doc) => {
          await deleteDoc(doc.ref);
        });
        notificationQuerySnapshot.forEach(async (doc) => {
          await deleteDoc(doc.ref);
        });
        // Delete the main car document
        await deleteDoc(carRef);
        window.location.reload();
      }
      const docRef = doc(
        db,
        `${collectionName}`,
        `${carName}`,
        `${collectionGroupName}`,
        `${documentId}`
      );

      await deleteDoc(docRef);
      console.log("Document successfully deleted!");
      // Refresh the current page
      window.location.reload();

      setModalOpen(false); // Close the modal after deletion
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div>
      <button className="deleteBtn" onClick={openModal}>
        Izbriši
      </button>

      {isModalOpen && (
        <div className="confirmation-dialog">
          <p>Da li si siguran da želiš obrisati ovu datoteku?</p>
          <button className="deleteBtn" onClick={handleDelete}>
            Izbriši
          </button>
          <button className="btn" onClick={closeModal}>
            Odustani
          </button>
        </div>
      )}
    </div>
  );
};

export default DeleteButton;
