import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { updateDoc, doc } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import "../form.css";

const UpdateFuelForm = ({ initialData, onClose }) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [mileage, setMileage] = useState(initialData.mileage);
  const [fuelDate, setFuelDate] = useState(initialData.fuelDate);
  const [emptyFields, setEmptyFields] = useState({
    mileage: false,
    fuelDate: false,
  });

  useEffect(() => {
    setMileage(initialData.mileage);
    setFuelDate(initialData.fuelDate);
  }, [initialData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {
      mileage: !mileage,
      fuelDate: !fuelDate,
    };

    setEmptyFields(errors);

    if (Object.values(errors).some(Boolean)) {
      console.error("All fields are required.");
      return;
    }

    const fuelData = {
      mileage,
      fuelDate,
    };

    const fuelDocRef = doc(
      db,
      "cars",
      `${initialData.carName}`,
      "milage",
      `${initialData.fuelId}`
    );

    try {
      // Update the fuel data in Firestore
      await updateDoc(fuelDocRef, fuelData);
      console.log("Fuel expense document successfully updated!");
      setFormSubmitted(true);

      // Close the form
      window.location.reload();
      onClose();
    } catch (error) {
      console.error("Error updating fuel expense document:", error.message);
    }
  };

  const modalContent = (
    <div className="custom-form-container modal-edit">
      <h2 style={{ paddingBottom: "20px" }}>Uredi kilometražu</h2>
      <form className="custom-form" onSubmit={handleSubmit}>
        <div className={`custom-form-group ${emptyFields.mileage && "error"}`}>
          <label className="custom-label">Stanje na kilometar satu:</label>
          <input
            className={`custom-input ${emptyFields.mileage && "error"}`}
            type="number"
            value={mileage}
            onChange={(e) => setMileage(e.target.value)}
          />
          {emptyFields.mileage && (
            <div className="error-message">
              Molimo unesite stanje na kilometar satu
            </div>
          )}
        </div>

        <div className={`custom-form-group ${emptyFields.fuelDate && "error"}`}>
          <label className="custom-label">Datum goriva:</label>
          <input
            className={`custom-input ${emptyFields.fuelDate && "error"}`}
            type="date"
            value={fuelDate}
            onChange={(e) => setFuelDate(e.target.value)}
          />
          {emptyFields.fuelDate && (
            <div className="error-message">Molimo unesite datum goriva</div>
          )}
        </div>

        <div>
          <button className="custom-submit-button" type="submit">
            Ažuriraj
          </button>
          <button
            style={{ borderRadius: "5px", marginLeft: "10px" }}
            className="deleteBtn"
            onClick={onClose}
          >
            Odustani
          </button>
        </div>

        {/* Display Form Submitted Message */}
        {/* {formSubmitted && <FormSubmitted onClose={onClose} />} */}
      </form>
    </div>
  );

  const appContainer = document.querySelector(".App");

  if (!appContainer) {
    console.error("Target container not found");
    return null;
  }

  // Use ReactDOM.createPortal after checking the target container
  return ReactDOM.createPortal(modalContent, appContainer);
};

export default UpdateFuelForm;
