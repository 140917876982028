import React, { useState } from "react";
import "./DataCardFuel.css";
import DeleteButton from "../UIComponents/DeleteButton";
import UpdateFuelForm from "../FormComponents/UpdateForms/UpdateFuelForm";

const DataCardFuel = ({ dataItem, cars, controls = true }) => {
  const [edit, setEdit] = useState();
  const car = cars?.find((car) => car.id === dataItem.carId) || {};

  return (
    <div className="fuel-card">
      <div className="fuel-header">
        {cars && car && (
          <h3>{`${car.manufacturer} ${car.model} - ${car.licensePlate}`}</h3>
        )}
        <p>Kilometraža {dataItem.mileage} km</p>
        <p>Datum: {dataItem.fuelDate}</p>
      </div>

      <div className="fuel-details">
        {controls && (
          <div style={{ display: "flex", gap: "10px" }}>
            {cars && car && (
              <DeleteButton
                collectionName={"cars"}
                carName={`${car.manufacturer}-${car.model}-${car.licensePlate}`}
                collectionGroupName={"milage"}
                documentId={dataItem.fuelId}
              />
            )}
            <button className="btn" onClick={() => setEdit(true)}>
              Uredi
            </button>
            {edit && (
              <UpdateFuelForm
                initialData={dataItem}
                onClose={() => setEdit(false)}
                // carName={`${dataItem.manufacturer}-${dataItem.model}-${dataItem.licensePlate}`}
              />
            )}
          </div>
        )}
        {/* Add more fuel-related data fields here */}
      </div>
    </div>
  );
};

export default DataCardFuel;
