import React from "react";
import img from "../../assets/correct.png";
import "./FormSubmitted.css";

const FormSubmitted = ({ onClose }) => {
  return (
    <div className="form-submitted-message">
      <img src={img} alt="" />
      <h2>
        Datoteka je uspješno <br /> postavljena!
      </h2>
      <button className="btn" onClick={() => window.location.reload()}>
        Osvježi
      </button>
      <button
        className="closeBtn"
        style={{ position: "relative", bottom: "0", right: "0" }}
        onClick={() => onClose()}
      >
        Zatvori
      </button>
    </div>
  );
};

export default FormSubmitted;
