// Header.js
import React from "react";
import "./style.css";

import logo from "../../assets/logo.png";
import menu from "../../assets/menu.png";

import Logout from "../../firebase/Logout";

import { useAuth } from "../../firebase/useAuth";

const Header = ({ handleClick, handleStartClick }) => {
  const { authenticated } = useAuth();
  return (
    <header>
      <div className="logo" onClick={handleStartClick}>
        <img src={logo} alt="" width={"70px"} height={"70px"} />
      </div>
      <h1 style={{ textAlign: "center" }} onClick={handleStartClick}>
        Vozni park <br /> Republički protokol
      </h1>

      <div className="auth-links">
        <img
          src={menu}
          width={"32px"}
          height={"32px"}
          alt=""
          id="menu"
          onClick={handleClick}
        />
        {authenticated ? (
          <Logout />
        ) : (
          <>
            {/* <a href="#">Register</a>
            <a href="#">Login</a> */}
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
