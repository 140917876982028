import React, { useState, useEffect } from "react";
import "./PDF.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useLocation } from "react-router-dom";

const PDFDocument = () => {
  const location = useLocation();
  const [warrantData, setWarrantData] = useState({});

  useEffect(() => {
    // Retrieve and set warrant data on component load
    const searchParams = new URLSearchParams(location.search);
    const retrievedWarrantData = Object.fromEntries(searchParams.entries());

    console.log(retrievedWarrantData);

    // Parse the drivers string back to an array
    if (retrievedWarrantData.drivers) {
      try {
        retrievedWarrantData.drivers = JSON.parse(retrievedWarrantData.drivers);
      } catch (error) {
        console.error("Error parsing drivers:", error);
      }
    }

    // If driverNames is present, split it into an array and store it in drivers
    if (retrievedWarrantData.driverNames) {
      retrievedWarrantData.drivers = retrievedWarrantData.driverNames
        .split(",")
        .map((name) => ({ name }));
    }

    setWarrantData(retrievedWarrantData);
  }, [location.search]);

  // Check if drivers array exists before mapping
  const driversArray = warrantData.drivers || [];

  const handleDownload = () => {
    const element = document.getElementById("pdf-container");

    if (element) {
      html2canvas(element).then((canvas) => {
        const pdf = new jsPDF({
          orientation: "landscape", // Set to landscape mode
          unit: "mm",
          format: "a4",
        });

        const imgData = canvas.toDataURL("image/png");
        const imgWidth = 295; // A4 width in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width + 10;

        pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
        pdf.save("your-document.pdf");
      });
    } else {
      console.error("Container element not found");
    }
  };
  const getDayMonth = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based

    return `${day}.${month}`;
  };
  const getYear = (dateString) => {
    const date = new Date(dateString);
    return date.getFullYear();
  };

  const renderDriverList = () => {
    const drivers = warrantData.drivers || [];
    const maxDrivers = 3;

    // Create an array of 3 placeholders, filling with empty strings or driver names
    const driverItems = Array.from({ length: maxDrivers }, (_, index) => (
      <li key={index}>
        <span>{drivers[index]?.name || ""}</span>
      </li>
    ));

    return driverItems;
  };
  const parts = warrantData.id ? warrantData.id.split("-") : [];
  const formattedWarrantId =
    parts.length > 0 ? `${parts[0]}-${parts.slice(1).join("/")}` : "";

  return (
    <div className="blue-theme">
      <div id="pdf-container" className="pdf-container">
        <div className="warrant">
          <div
            style={{
              // border: "1px solid blue",
              display: "flex",
              position: "relative",
              padding: "0px",
            }}
          >
            <div
              style={{
                border: "1px solid blue",
                // display: "flex",
                position: "relative",
                width: "80%",
              }}
            >
              <h3
                style={{
                  textAlign: "center",
                }}
              >
                Obrazac putnog naloga za putnički automobil
              </h3>
            </div>
            <div
              style={{
                width: "100px",
                position: "absolute",
                right: "0",
                border: "1px solid blue",
              }}
            >
              <h3
                style={{
                  textAlign: "center",
                }}
              >
                (PN-4)
              </h3>
            </div>
          </div>
          <div>
            <p>
              Firma: <span>{warrantData.company}</span>
            </p>
            <div style={{ display: "flex" }}>
              <p>
                Mjesto: <span>{warrantData.location}</span>
              </p>
              <p>
                dana <span> {getDayMonth(warrantData.date)} </span>/
                <span> {getYear(warrantData.date)}</span> godine{" "}
              </p>
            </div>
          </div>
          <div>
            <h2>PUTNI NALOG</h2>
            <h4 style={{ textAlign: "center" }}>
              Broj: <span>{formattedWarrantId}</span>{" "}
            </h4>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ width: "50%" }}>
              <p style={{ padding: "0px 8px" }}>Prezime i ime vozača:</p>
            </div>
            <div>
              <ol style={{ margin: "8px 0px" }}>{renderDriverList()}</ol>
            </div>
          </div>
          <div>
            <p>
              Relacija: <span>{warrantData.route}</span>
            </p>
          </div>
          <div>
            <table>
              <thead>
                <tr>
                  <th>Marka i tip putničkog automobila</th>
                  <th>Broj sjedišta</th>
                  <th>Registarska oznaka vozila</th>
                </tr>
              </thead>
              <tbody>
                <td>{warrantData.carDetails}</td>
                <td>{warrantData.numSeats}</td>
                <td>{warrantData.registrationPlate}</td>
              </tbody>
            </table>
            <p style={{ textAlign: "center" }}>
              Potvrda o ispravnosti vozila za vožnju:
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <div>
              <p
                style={{
                  textAlign: "center",
                }}
              >
                Vozilo primio bez <br /> vidljivih nedostataka:
              </p>
              <div className="line"></div>
              <p
                style={{
                  textAlign: "center",
                }}
              >
                (Potpis vozača)
              </p>
            </div>
            <div>
              <div style={{ height: "31px" }}></div>
              <div className="line"></div>
              <p
                style={{
                  textAlign: "center",
                }}
              >
                (Potpis ovlašćenog lica za <br /> izdavanje putnog naloga )
              </p>
            </div>
          </div>
        </div>

        <table className="pdf-table">
          <thead>
            <td colspan="9">
              <h2>Evidencija utroška goriva i maziva</h2>
            </td>
          </thead>
          <thead>
            <tr>
              <th>Datum</th>
              <th>Mjesto tankovanja</th>
              <th>Stanje brojila Km</th>
              <th>Plinsko ulje</th>
              <th>Benzin</th>
              <th>Motorno ulje</th>
              <th>Ostala maziva</th>
              <th>Broj računa</th>
              <th>Pumpna stanica (potpis i pečat)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td style={{ borderRight: "none" }}>UKUPNO:</td>
              <td style={{ borderLeft: "none" }}></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="9" style={{ borderBottom: "none" }}>
                Napomena:
              </td>
            </tr>
            <tr>
              <td
                colspan="7"
                style={{ borderTop: "none", borderRight: "none" }}
              ></td>
              <td colspan="2" style={{ borderLeft: "none" }}>
                {" "}
                <p style={{ textAlign: "center" }}>(Evidentirao)</p>{" "}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <button className="pdf" onClick={handleDownload}>
        Download PDF
      </button>
    </div>
  );
};

export default PDFDocument;
