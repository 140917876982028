import React, { useEffect, useState } from "react";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import "./DataCardWarrant.css"; // Import your CSS file for styling
import { useNavigate } from "react-router-dom";

import UpdateWarrantForm from "../FormComponents/UpdateForms/UpdateWarrantForm";
import DeleteButton from "../UIComponents/DeleteButton";

const DataCardWarrant = ({ warrantData }) => {
  const navigate = useNavigate();
  const [drivers, setDrivers] = useState([]);
  const [edit, setEdit] = useState();

  useEffect(() => {
    const fetchDrivers = async () => {
      const driversCollection = collection(db, "drivers");

      try {
        const driverQuerySnapshot = await getDocs(driversCollection);
        const driverList = driverQuerySnapshot.docs.map((driverDoc) => ({
          id: driverDoc.id,
          ...driverDoc.data(),
        }));
        // console.log(driverList);
        setDrivers(driverList);
      } catch (error) {
        console.error("Error fetching drivers: ", error);
      }
    };

    fetchDrivers();
  }, [db]);

  const handleButtonClick = () => {
    const driverNamesString = warrantData.drivers
      .map((driver) => driver.name)
      .join(",");

    navigate(
      `/pdf-putninalog?${new URLSearchParams({
        ...warrantData,
        driverNames: driverNamesString,
      }).toString()}`
    );
  };

  return (
    <div className="warrant-card">
      <div className="warrant-header">
        <h3>Firma: {warrantData.company}</h3>
      </div>
      <div className="warrant-details">
        <p>Mjesto: {warrantData.location}</p>
        <p>Datum: {warrantData.date}</p>
        <p>ID: {warrantData.id}</p>
        {warrantData.drivers &&
          drivers.length > 0 &&
          warrantData.drivers.map((warrantDriver, index) => {
            // console.log(drivers, warrantDriver);
            const matchedDriver = drivers.find(
              (driver) => driver.id === warrantDriver.id
            );
            // console.log(matchedDriver);
            const driverName = matchedDriver.driverName;

            return <li key={index}>{driverName}</li>;
          })}
        <p>Relacija: {warrantData.route}</p>
        <p>Marka i tip putničkog automobila: {warrantData.carDetails}</p>
        <p>Broj sjedista: {warrantData.numSeats}</p>
        <p>Registarska oznaka vozila: {warrantData.registrationPlate}</p>
        <div style={{ display: "flex", gap: "10px" }}>
          <button
            className="btn"
            style={{ marginTop: "10px" }}
            onClick={handleButtonClick}
          >
            Otvori PDF 1
          </button>
          <button
            className="btn"
            style={{ marginTop: "10px" }}
            onClick={() => navigate("/pdf-drugapdfstrana")}
          >
            Otvori PDF 2
          </button>
          <button onClick={() => setEdit(true)} className="btn">
            Uredi
          </button>
          {edit && (
            <UpdateWarrantForm
              warrantData={warrantData}
              onClose={() => setEdit(false)}
            />
          )}
          {/* <DeleteButton collectionName={"warrants"} carName={warrantData.id} /> */}
        </div>
      </div>
    </div>
  );
};

export default DataCardWarrant;
