import { getAuth, signOut } from "firebase/auth";

function Logout() {
  const auth = getAuth();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      console.log("User has been logged out");
    } catch (error) {
      console.log(error);
    }
  };

  return <a onClick={handleLogout}>Odjavi se</a>;
}

export default Logout;
