import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import DataCardService from "./DataCardService";
import DataCardFuel from "./DataCardFuel";
import CardList from "./CardList";
import "./CustomModal.css";
import { faHourglass1 } from "@fortawesome/free-solid-svg-icons";

const CustomModal = ({ isOpen, onRequestClose, data }) => {
  const [dataType, setDataType] = useState("");

  useEffect(() => {
    if (data && data.length > 0) {
      const firstItem = data[0];

      if (firstItem.hasOwnProperty("serviceId")) {
        setDataType("service");
        // Handle service type
      } else if (firstItem.hasOwnProperty("fuelId")) {
        setDataType("fuel");
        // Handle fuel type
      }
    }
  }, [data]);

  const modalContent = (
    <div className="overlay" onClick={onRequestClose}>
      <div className="modal">
        {data &&
          data.length > 0 &&
          dataType === "service" &&
          data.map((card, i) => {
            return <DataCardService key={i} dataItem={card} controls={false} />;
          })}
        {data &&
          data.length > 0 &&
          dataType === "fuel" &&
          data
            .slice() // create a shallow copy of the array to avoid mutating the original
            .sort((a, b) => new Date(b.fuelDate) - new Date(a.fuelDate))
            .map((card, i) => (
              <DataCardFuel key={i} dataItem={card} controls={false} />
            ))}
        {data && data.length === 0 && <h3>Nema pronadjenih rezultata</h3>}

        {/* Your modal content goes here */}
        <button
          className="closeBtn"
          style={{ position: "fixed" }}
          onClick={onRequestClose}
        >
          Zatvori
        </button>
      </div>
    </div>
  );

  const appContainer = document.querySelector(".App");

  if (!appContainer) {
    console.error("Target container not found");
    return null;
  }

  // Use ReactDOM.createPortal after checking the target container
  return isOpen ? ReactDOM.createPortal(modalContent, appContainer) : null;
};

export default CustomModal;
