import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { updateDoc, doc } from "firebase/firestore";
import { collection, getDocs, collectionGroup } from "firebase/firestore";

import { db } from "../../../firebase/firebase";

const UpdateWarrantForm = ({ warrantData, onClose }) => {
  // const db = getFirestore(app);

  const [cars, setCars] = useState();
  const [company, setCompany] = useState(warrantData.company || "");
  const [location, setLocation] = useState(warrantData.location || "");
  const [date, setDate] = useState(warrantData.date || "");
  const [selectedDrivers, setSelectedDrivers] = useState(
    warrantData.drivers.map((driver) => driver.id) || []
  );

  const [drivers, setDrivers] = useState([{ driverName: "" }]);

  const [route, setRoute] = useState(warrantData.route || "");
  const [carDetails, setCarDetails] = useState(warrantData.carDetails || "");
  const [numSeats, setNumSeats] = useState(warrantData.numSeats || "");
  const [registrationPlate, setRegistrationPlate] = useState(
    warrantData.registrationPlate || ""
  );
  const [error, setError] = useState(false);

  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    const fetchDrivers = async () => {
      const driversCollection = collection(db, "drivers");

      try {
        const driverQuerySnapshot = await getDocs(driversCollection);
        const driverList = driverQuerySnapshot.docs.map((driverDoc) => ({
          id: driverDoc.id,
          driverName: driverDoc.data().driverName || "", // Ensure driverName is set
        }));
        setDrivers(driverList);
      } catch (error) {
        console.error("Error fetching drivers: ", error);
      }
    };

    const fetchCars = async () => {
      const carsCollection = collectionGroup(db, "info");

      try {
        const querySnapshot = await getDocs(carsCollection);
        const carList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCars(carList);
      } catch (error) {
        console.error("Error fetching cars: ", error);
      }
    };

    fetchDrivers();
    fetchCars();
  }, [db]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Update the existing warrant document
    const warrantRef = doc(db, "warrants", warrantData.id);

    try {
      await updateDoc(warrantRef, {
        company,
        location,
        date,
        drivers: selectedDrivers.map((driverId) => {
          const selectedDriver = drivers.find(
            (driver) => driver.id === driverId
          );
          return {
            id: driverId,
            name: selectedDriver ? selectedDriver.driverName : "",
          };
        }),
        route,
        carDetails,
        numSeats,
        registrationPlate,
        // ... update other fields
      });

      console.log("Warrant document successfully updated!");
      window.location.reload();
      onClose();
    } catch (error) {
      console.error("Error updating warrant document: ", error);
    }
  };
  const addSelectedDriver = () => {
    if (selectedDrivers.length < 3) {
      setSelectedDrivers([...selectedDrivers, ""]);
    } else {
      console.warn("Maximum number of drivers reached (3).");
    }
  };

  const removeSelectedDriver = (index) => {
    const updatedSelectedDrivers = [...selectedDrivers];
    updatedSelectedDrivers.splice(index, 1);
    setSelectedDrivers(updatedSelectedDrivers);
  };

  const updateSelectedDriver = (index, value) => {
    const updatedSelectedDrivers = [...selectedDrivers];
    updatedSelectedDrivers[index] = value;
    setSelectedDrivers(updatedSelectedDrivers);
  };
  // const fetchSelectedDriverNames = async () => {
  //   const selectedDriverNames = await Promise.all(
  //     selectedDrivers.map(async (driverId) => {
  //       const selectedDriver = drivers.find((driver) => driver.id === driverId);
  //       return selectedDriver ? selectedDriver.driverName : "";
  //     })
  //   );

  //   return selectedDriverNames;
  // };

  // useEffect(() => {
  //   const updateSelectedDriverNames = async () => {
  //     const names = await fetchSelectedDriverNames();
  //     setSelectedDriverNames(names);
  //   };

  //   updateSelectedDriverNames();
  // }, [selectedDrivers, drivers]);

  const modalContent = (
    <div className="custom-form-container modal-edit">
      <h2 style={{ paddingBottom: "20px" }}>Uredi putni nalog</h2>
      <form className="custom-form" onSubmit={handleSubmit}>
        <div className="custom-form-group">
          <label className="custom-label">Firma:</label>
          <input
            className="custom-input"
            type="text"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />
        </div>

        <div className="custom-form-group">
          <label className="custom-label">Mjesto:</label>
          <input
            className="custom-input"
            type="text"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </div>

        <div className="custom-form-group">
          <label className="custom-label">Datum:</label>
          <input
            className="custom-input"
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>

        <div className="custom-form-group">
          <label htmlFor="" className="custom-label">
            Vozači:
          </label>
          {selectedDrivers.map((selectedDriverId, index) => (
            <div key={index} style={{ display: "flex", alignItems: "center" }}>
              <select
                name={`selectedDriver${index}`}
                value={selectedDriverId}
                onChange={(e) => updateSelectedDriver(index, e.target.value)}
                className="custom-select"
              >
                <option value="">Izaberi vozača</option>
                {drivers.map((driver) => (
                  <option key={driver.id} value={driver.id}>
                    {driver.driverName}
                  </option>
                ))}
              </select>
              {index > 0 && (
                <button
                  type="button"
                  className="deleteBtn"
                  onClick={() => removeSelectedDriver(index)}
                  style={{ marginLeft: "10px", marginTop: "0px" }}
                >
                  Ukloni vozača
                </button>
              )}
            </div>
          ))}

          {selectedDrivers.length < 3 && (
            <button className="btn" type="button" onClick={addSelectedDriver}>
              Dodaj Vozača
            </button>
          )}
        </div>

        <div className="custom-form-group">
          <label className="custom-label">Relacija:</label>
          <input
            className="custom-input"
            type="text"
            value={route}
            onChange={(e) => setRoute(e.target.value)}
          />
        </div>

        {/* Add other input fields for editing */}
        {/* Add other input fields for editing */}
        {/* ... Add other input fields for editing */}
        {/* ... Add other input fields for editing */}

        {error && (
          <div className="error-message">Sva polja moraju biti popunjena</div>
        )}

        <div>
          <button className="custom-submit-button" type="submit">
            Ažuriraj
          </button>
          <button
            style={{ borderRadius: "5px", marginLeft: "10px" }}
            className="deleteBtn"
            onClick={onClose}
          >
            Odustani
          </button>
        </div>
      </form>
    </div>
  );
  const appContainer = document.querySelector(".App");

  if (!appContainer) {
    console.error("Target container not found");
    return null;
  }

  // Use ReactDOM.createPortal after checking the target container
  return ReactDOM.createPortal(modalContent, appContainer);
};

export default UpdateWarrantForm;
