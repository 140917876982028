import React, { useState } from "react";
import "./style.css";
import closeIcon from "../../assets/x.png"; // Import your close button icon
import { useNavigate } from "react-router-dom";

const Sidebar = ({ onLinkClick, isOpen, handleClick }) => {
  const [selectedLink, setSelectedLink] = useState("");

  const handleLinkClick = (link) => {
    onLinkClick(link);
    setSelectedLink(link);
  };

  return (
    <aside className={`sidebar ${isOpen ? "open" : ""}`}>
      <button className="close-button" onClick={handleClick}>
        <img src={closeIcon} alt="Close" />
      </button>
      <nav>
        <ul>
          <li>
            <h3
              onClick={() => handleLinkClick("")}
              className={selectedLink === "" ? "highlighted" : ""}
            >
              Početna
            </h3>
          </li>
          <li
            onClick={() => handleLinkClick("cars")}
            className={selectedLink === "cars" ? "highlighted" : ""}
          >
            Vozila
          </li>
          <li
            onClick={() => handleLinkClick("services")}
            className={selectedLink === "services" ? "highlighted" : ""}
          >
            Servisi
          </li>
          <li
            onClick={() => handleLinkClick("drivers")}
            className={selectedLink === "drivers" ? "highlighted" : ""}
          >
            Vozaci
          </li>
          <li
            onClick={() => handleLinkClick("fuel")}
            className={selectedLink === "fuel" ? "highlighted" : ""}
          >
            Kilometraža
          </li>
          <li
            onClick={() => handleLinkClick("warrants")}
            className={selectedLink === "warrants" ? "highlighted" : ""}
          >
            Putni Nalog
          </li>
          <li
            onClick={() => handleLinkClick("calendar")}
            className={selectedLink === "calendar" ? "highlighted" : ""}
          >
            Kalendar
          </li>
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
