import React, { useState, useEffect } from "react";
import {
  getFirestore,
  query,
  orderBy,
  collection,
  getDocs,
  collectionGroup,
} from "firebase/firestore";
import { useData } from "../FunctionComponents/DataProvider.jsx";
import { app } from "../../firebase/firebase.js";
import DataCardCar from "./DataCardCar.jsx";
import DataCardService from "./DataCardService.jsx";
import DataCardDriver from "./DataCardDriver.jsx";
import DataCardFuel from "./DataCardFuel.jsx";
import DataCardWarrant from "./DataCardWarrant.jsx";
import FilteringComponent from "../FunctionComponents/FilteringComponent.jsx";
import "./card.css";

import { css } from "@emotion/react";
import SyncLoader from "react-spinners/SyncLoader";

const CardList = ({ selectedCategory }) => {
  const db = getFirestore(app);
  const { data } = useData();
  const [loading, setLoading] = useState(true);
  const [cars, setCars] = useState([]);
  const [filters, setFilters] = useState({
    selectedCar: "",
    searchQuery: "",
    sortBy: "",
  });

  useEffect(() => {
    const fetchCars = async () => {
      const carsCollection = collectionGroup(db, "info");
      const carsQuery = query(carsCollection, orderBy("manufacturer"));

      try {
        const querySnapshot = await getDocs(carsQuery);
        const carList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setLoading(false);
        setCars(carList);
      } catch (error) {
        console.error("Error fetching cars: ", error);
      }
    };

    fetchCars();
  }, [db]);

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  const filteredData = Array.isArray(data)
    ? data
        .filter((item) => {
          if (
            selectedCategory === "services" ||
            selectedCategory === "fuel" ||
            selectedCategory === "warrants"
          ) {
            console.log(
              filters.selectedDriver,
              item.drivers,
              (item.drivers || []).some(
                (driver) => driver.id === filters.selectedDriver
              )
            );
            if (
              filters.selectedDriver &&
              !(item.drivers || []).some(
                (driver) => driver.id === filters.selectedDriver
              )
            ) {
              return false;
            }

            if (
              filters.selectedCar !== "" &&
              String(item.carId) !== filters.selectedCar
            ) {
              return false;
            }

            if (
              filters.searchQuery &&
              !item.serviceName
                .toLowerCase()
                .includes(filters.searchQuery.toLowerCase())
            ) {
              return false;
            }
            if (
              selectedCategory === "warrants" &&
              filters.startDate &&
              new Date(item.date) < new Date(filters.startDate)
            ) {
              return false;
            }
            if (
              selectedCategory === "warrants" &&
              filters.endDate &&
              new Date(item.date) > new Date(filters.endDate)
            ) {
              return false;
            }
            if (
              filters.startDate &&
              new Date(
                item[
                  selectedCategory === "services" ? "serviceDate" : "fuelDate"
                ]
              ) < new Date(filters.startDate)
            ) {
              return false;
            }
            if (
              filters.endDate &&
              new Date(
                item[
                  selectedCategory === "services" ? "serviceDate" : "fuelDate"
                ]
              ) > new Date(filters.endDate)
            ) {
              return false;
            }

            if (
              filters.nextServiceStartDate &&
              new Date(item.nextServiceDate) <
                new Date(filters.nextServiceStartDate)
            ) {
              return false;
            }
            if (
              filters.nextServiceEndDate &&
              new Date(item.nextServiceDate) >
                new Date(filters.nextServiceEndDate)
            ) {
              return false;
            }

            return true;
          }
          // Add similar filtering logic for other categories if needed
          return true;
        })
        .sort((a, b) => {
          if (
            (selectedCategory === "services" || selectedCategory === "fuel") &&
            filters.sortBy ===
              (selectedCategory === "services" ? "serviceDate" : "fuelDate")
          ) {
            return (
              new Date(
                b[selectedCategory === "services" ? "serviceDate" : "fuelDate"]
              ) -
              new Date(
                a[selectedCategory === "services" ? "serviceDate" : "fuelDate"]
              )
            );
          }
          // Add sorting logic for other categories if needed
          return 0;
        })
    : [];

  if (loading) {
    const override = css`
      display: block;
      margin: 0 auto;
    `;
    return (
      <div className="spinner-container">
        <SyncLoader
          color={"#007bff"}
          loading={loading}
          css={override}
          size={10}
        />
      </div>
    );
  }

  if (!data.length) {
    return <div>Nema Pronadjenih Rezultata</div>;
  }

  return (
    <>
      {selectedCategory === "services" && (
        <>
          <FilteringComponent onFilterChange={handleFilterChange} cars={cars} />
          <div className="card-list-container">
            {filteredData.map((dataItem, index) => (
              <div className="card" key={index}>
                <DataCardService dataItem={dataItem} cars={cars} />
              </div>
            ))}
          </div>
        </>
      )}

      {selectedCategory === "cars" && (
        <div className="card-list-container">
          {data.map((dataItem, index) => {
            if (selectedCategory === "cars") {
              return (
                <div className="card" key={index}>
                  <DataCardCar dataItem={dataItem} />
                </div>
              );
            }
            return null;
          })}
        </div>
      )}

      {selectedCategory === "warrants" && (
        <>
          <FilteringComponent
            drivers={true}
            search={false}
            dates={false}
            onFilterChange={handleFilterChange}
            cars={cars}
          />
          <div className="card-list-container">
            {filteredData.map((dataItem, index) => (
              <div className="card" key={index}>
                <DataCardWarrant warrantData={dataItem} />
              </div>
            ))}
          </div>
        </>
      )}

      {selectedCategory === "fuel" && (
        <>
          <FilteringComponent
            search={false}
            dates={false}
            onFilterChange={handleFilterChange}
            cars={cars}
          />
          <div className="card-list-container">
            {filteredData.map((dataItem, index) => (
              <div className="card" key={index}>
                <DataCardFuel dataItem={dataItem} cars={cars} />
              </div>
            ))}
          </div>
        </>
      )}

      <div className="card-list-container">
        {data.map((dataItem, index) => {
          if (selectedCategory === "drivers") {
            return (
              <div className="card" key={index}>
                <DataCardDriver dataItem={dataItem} />
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    </>
  );
};

export default CardList;
