import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const AuthContext = createContext({
  user: null,
  logout: () => {
    console.log("nothing");
  },
  authenticated: null,
});

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
      setIsLoaded(true);
    });

    return () => unsubscribe();
  }, []);

  const logout = async () => {
    const auth = getAuth();
    await auth.signOut();
    navigate("/"); // Redirect to home or login page after logout
  };

  if (!isLoaded) {
    return <div>Getting Info...</div>;
  }

  return (
    <AuthContext.Provider value={{ user, authenticated: !!user, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const authContext = useContext(AuthContext);

  // Add this part to expose the user's email
  const { user } = authContext;
  const userEmail = user ? user.email : null;

  return { ...authContext, userEmail };
}
