import React, { useState, useEffect } from "react";
import { addDoc, collection, collectionGroup } from "firebase/firestore";
import { getFirestore, doc, setDoc, getDocs } from "firebase/firestore";
import { app } from "../../firebase/firebase";
import FormSubmitted from "./FormSubmitted";

import { useAuth } from "../../firebase/useAuth";

const FuelForm = ({ toggleEnable }) => {
  const { userEmail } = useAuth();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [emptyFields, setEmptyFields] = useState({
    selectedCar: false,
    mileage: false,
    fuelDate: false,
  });
  const db = getFirestore(app);

  const [cars, setCars] = useState([]);
  const [selectedCar, setSelectedCar] = useState({ id: "", name: "" });
  const [mileage, setMileage] = useState("");
  const [fuelDate, setFuelDate] = useState("");

  function generateRandom8DigitNumber() {
    const min = 10000000; // Smallest 8-digit number (10^7)
    const max = 99999999; // Largest 8-digit number (10^8 - 1)

    // Generate a random number between min and max
    const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;

    return randomNum;
  }

  useEffect(() => {
    // Fetch the list of cars when the component mounts
    const fetchCars = async () => {
      const carsCollection = collectionGroup(db, "info");

      try {
        const carQuerySnapshot = await getDocs(carsCollection);
        const carList = carQuerySnapshot.docs.map((carDoc) => ({
          id: carDoc.id,
          ...carDoc.data(),
        }));
        setCars(carList);
      } catch (error) {
        console.error("Error fetching cars: ", error);
      }
    };

    fetchCars();
  }, [db]);

  const [mileageData, setMileageDate] = useState();
  useEffect(() => {
    // Fetch new mileage data when the selected car changes
    let isMounted = true;

    const fetchData = async () => {
      try {
        const carsCollection = collectionGroup(db, "milage");
        const carQuerySnapshot = await getDocs(carsCollection);
        const carList = carQuerySnapshot.docs.map((carDoc) => ({
          id: carDoc.id,
          ...carDoc.data(),
        }));

        if (isMounted) {
          setMileageDate(carList);
        }

        // If selectedCar.id is truthy, filter the data
        if (selectedCar.id) {
          const filteredData = carList.filter(
            (item) => item.carId === selectedCar.id
          );

          if (filteredData.length > 0) {
            const highestMileageEntry = filteredData.reduce((max, entry) =>
              entry.mileage > max.mileage ? entry : max
            );

            if (isMounted) {
              setMileageDate(highestMileageEntry);
            }
          } else {
            // If no mileage data is found for the selected car, reset the state
            if (isMounted) {
              setMileageDate("");
            }
          }
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();

    // Cleanup function to handle component unmounting
    return () => {
      isMounted = false;
    };
  }, [selectedCar.id, db]);
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check for empty fields
    const errors = {
      selectedCar: !selectedCar.id,
      mileage: !mileage,
      fuelDate: !fuelDate,
    };

    setEmptyFields(errors);

    // If any field is empty, prevent form submission
    if (Object.values(errors).some(Boolean)) {
      console.error("All fields are required.");
      return;
    }

    const fuelData = {
      fuelId: generateRandom8DigitNumber(),
      carId: selectedCar.id,
      carName: selectedCar.name,
      mileage,
      fuelDate,
      user: userEmail,
    };

    const fuelDocRef = doc(
      db,
      "cars",
      `${selectedCar.name}`,
      "milage",
      `${fuelData.fuelId}`
    );
    // const fuelDocRef = doc(
    //   db,
    //   "cars",s
    //   `${selectedCar.name}`,
    //   "notificationService",
    //   `${fuelData.fuelId}`
    // );

    try {
      // Add the fuel expense data to Firestore
      await setDoc(fuelDocRef, fuelData);
      console.log("Fuel expense document successfully written!");
      setFormSubmitted(true);

      // Reset form fields and errors
      setSelectedCar("");
      setMileage("");
      setFuelDate("");
      setEmptyFields({
        selectedCar: false,
        mileage: false,
        fuelDate: false,
      });
    } catch (error) {
      console.error("Error writing fuel expense document: ", error);
    }
  };

  function onClose() {
    setFormSubmitted(false);
  }
  const isInvalidMileage =
    mileage !== "" && parseInt(mileage, 10) < mileageData.mileage;
  return (
    <div className="custom-form-container">
      <h2 style={{ paddingBottom: "20px" }}>Dodajte kilometražu</h2>
      <form className="custom-form" onSubmit={handleSubmit}>
        <div
          className={`custom-form-group ${emptyFields.selectedCar && "error"}`}
        >
          <label className="custom-label">Izaberite automobil:</label>
          <select
            className={`custom-select ${emptyFields.selectedCar && "error"}`}
            value={selectedCar.id}
            onChange={(e) => {
              const selectedCarId = e.target.value;
              const selectedCarName =
                e.target.options[e.target.selectedIndex].text;

              setSelectedCar({
                id: selectedCarId,
                name: selectedCarName,
              });

              // Reset the error message when the user selects a car
              setEmptyFields((prev) => ({ ...prev, selectedCar: false }));
            }}
          >
            <option value="">Izaberite automobil</option>
            {cars.map((car) => (
              <option key={car.id} value={car.id}>
                {car.manufacturer}-{car.model}-{car.licensePlate}
              </option>
            ))}
          </select>
          {emptyFields.selectedCar && (
            <div className="error-message">Molimo izaberite automobil</div>
          )}
        </div>
        {mileageData && mileageData.mileage && (
          <div className="info-box">
            <p>
              Poslednje stanje: {mileageData.mileage} km <br />
              {mileageData.fuelDate}:{" "}
            </p>
          </div>
        )}

        <div className={`custom-form-group ${emptyFields.mileage && "error"}`}>
          <label className="custom-label">Stanje na kilometar satu: </label>
          <input
            className={`custom-input`}
            type="number"
            value={mileage}
            onChange={(e) => setMileage(e.target.value)}
          />
          {isInvalidMileage && (
            <div className="error-message">
              Molimo unesite kilometražu veću od posledjnje unesene
            </div>
          )}
          {emptyFields.mileage && (
            <div className="error-message">
              Molimo unesite stanje na kilometar satu
            </div>
          )}
        </div>

        <div className={`custom-form-group ${emptyFields.fuelDate && "error"}`}>
          <label className="custom-label">Datum unosa:</label>
          <input
            className={`custom-input ${emptyFields.fuelDate && "error"}`}
            type="date"
            value={fuelDate}
            onChange={(e) => setFuelDate(e.target.value)}
          />
          {emptyFields.fuelDate && (
            <div className="error-message">
              Molimo unesite datum kilometraže
            </div>
          )}
        </div>

        <div>
          <button className="custom-submit-button" type="submit">
            Prihvati
          </button>
          <button
            className="deleteBtn"
            style={{ borderRadius: "5px", marginLeft: "10px" }}
            onClick={toggleEnable}
            type="button"
          >
            Odustani
          </button>
        </div>
        {formSubmitted && <FormSubmitted onClose={onClose} />}
      </form>
    </div>
  );
};

export default FuelForm;
