import React, { useState, useEffect } from "react";
import "./DataCardService.css";
import { collectionGroup, getDocs } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import DeleteButton from "../UIComponents/DeleteButton";
import UpdateServiceForm from "../FormComponents/UpdateForms/UpdateServiceForm";

const DataCardService = ({ dataItem, cars, controls = true }) => {
  const [edit, setEdit] = useState();
  const [highestMileage, setHighestMileage] = useState(0);

  const car = cars?.find((car) => car.id === dataItem.carId) || {};
  const mileageNumber = +dataItem.mileage + +dataItem.nextServiceMileage;

  useEffect(() => {
    // Fetch the highest mileage for the specific car from the database
    const fetchHighestMileage = async () => {
      try {
        const mileageData = await collectionGroup(db, "milage");
        const querySnapshot = await getDocs(mileageData);
        const categoryData = querySnapshot.docs.map((doc) => doc.data());

        // Find the object with carName equal to dataItem.carName
        const carObject = categoryData.find(
          (item) => item.carName === dataItem.carName
        );

        // Ensure that carObject is not undefined
        if (carObject && carObject.mileage) {
          // Extract mileage values from all objects
          const mileageValues = categoryData
            .map((item) => parseInt(item.mileage, 10))
            .filter((value) => !isNaN(value));

          // Find the highest mileage value
          const highestMileage = Math.max(...mileageValues);
          console.log(highestMileage);

          // Store the highest mileage using setHighestMileage function
          setHighestMileage(highestMileage);
        }
      } catch (error) {
        console.error("Error fetching mileage data", error);
      }
    };

    fetchHighestMileage();
  }, [dataItem.carId]);
  return (
    <div className="service-card">
      <div className="service-header">
        <h3>Servis: {dataItem.serviceName}</h3>
      </div>
      <div className="service-details">
        <p>Cijena servisa: {dataItem.serviceCost} KM</p>
        <p>Datum servisa: {dataItem.serviceDate}</p>

        {/* Check if there is a next service date */}
        {dataItem.needsServicing && dataItem.nextServiceDate && (
          <p>Sledeći servis: {dataItem.nextServiceDate}</p>
        )}
        {dataItem.nextServiceMileage && (
          <p>
            Ponovni servis za:
            {+highestMileage > +mileageNumber ? (
              <span>
                {+mileageNumber -
                  +highestMileage +
                  +dataItem.nextServiceMileage}{" "}
                km
              </span>
            ) : (
              <span>{+mileageNumber - +highestMileage} km</span>
            )}
          </p>
        )}

        {controls && (
          <div style={{ display: "flex", gap: "10px" }}>
            {car && (
              <DeleteButton
                collectionName={"cars"}
                carName={`${car.manufacturer}-${car.model}-${car.licensePlate}`}
                collectionGroupName={"services"}
                documentId={dataItem.serviceId}
                serviceName={dataItem.serviceName}
                nextServiceMileage={dataItem.nextServiceMileage}
                nextServiceDate={dataItem.nextServiceDate}
              />
            )}
            <button className="btn" onClick={() => setEdit(true)}>
              Uredi
            </button>
            {edit && (
              <UpdateServiceForm
                initialData={dataItem}
                onClose={() => setEdit(false)}
                // carName={`${dataItem.manufacturer}-${dataItem.model}-${dataItem.licensePlate}`}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DataCardService;
