// DataProvider.js
import React, { createContext, useContext, useState, useEffect } from "react";
import {
  collection,
  query,
  orderBy,
  getDocs,
  collectionGroup,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";

const DataContext = createContext();

export const DataProvider = ({ children, selectedCategory }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryCollection = collectionGroup(db, selectedCategory);
        let categoryQuery = null;

        if (selectedCategory === "cars") {
          categoryQuery = query(
            collectionGroup(db, "info"),
            orderBy("manufacturer")
          );
        } else if (selectedCategory === "services") {
          categoryQuery = query(
            collectionGroup(db, "services"),
            orderBy("serviceDate")
          );
        } else if (selectedCategory === "drivers") {
          categoryQuery = query(
            collection(db, "drivers"),
            orderBy("driverName")
          );
        } else if (selectedCategory === "fuel") {
          categoryQuery = query(
            collectionGroup(db, "milage"),
            orderBy("fuelDate")
          );
        } else if (selectedCategory === "warrants") {
          categoryQuery = query(collection(db, "warrants"), orderBy("date"));
        }

        if (categoryQuery) {
          const querySnapshot = await getDocs(categoryQuery);
          const categoryData = querySnapshot.docs.map((doc) => doc.data());
          setData(categoryData);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, [selectedCategory]);

  return (
    <DataContext.Provider value={{ data }}>{children}</DataContext.Provider>
  );
};

export const useData = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("useData must be used within a DataProvider");
  }
  return context;
};
