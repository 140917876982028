// PlusSign.js
import React, { useState } from "react";
import Form from "../FormComponents/Form";
import "./style.css"; // Import CSS file

const PlusSign = ({ selectedCategory }) => {
  const [showForm, setShowForm] = useState(false);

  const handleClick = () => {
    setShowForm(!showForm);
  };

  return (
    <div>
      <Form
        selectedCategory={selectedCategory}
        enable={showForm}
        toggleEnable={handleClick}
      />

      <div
        className={`plus-sign ${showForm ? "slide-out" : ""}`}
        onClick={handleClick}
      >
        +
      </div>
    </div>
  );
};

export default PlusSign;
