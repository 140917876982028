import React, { useState } from "react";
import "./DataCardDriver.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import DeleteButton from "../UIComponents/DeleteButton";
import UpdateUserProfileForm from "../FormComponents/UpdateForms/UpdateUserProfileForm";

const DataCardDriver = ({ dataItem }) => {
  const [edit, setEdit] = useState();
  const [showInfo, setShowInfo] = useState(false);

  const toggleInfo = () => {
    setShowInfo(!showInfo);
  };

  return (
    <div className="user-profile-card">
      <div className="user-details">
        <>
          <h3 className="user-name">{dataItem.driverName}</h3>
          <div className="user-info">
            <p>
              <FontAwesomeIcon icon={faEnvelope} /> Email: {dataItem.email}
            </p>
            <p>
              <FontAwesomeIcon icon={faPhone} /> Telefon: {dataItem.phone}
            </p>
          </div>
          <p>Informacije o licenci: {dataItem.licenseInfo}</p>
          {/* <button className="info-button" onClick={toggleInfo}>
            {showInfo ? "✕" : "i"}
          </button> */}
        </>
        {/* Add more driver-specific data fields here */}
        <div
          style={{
            display: "flex",
            gap: "10px",
            // position: "relative",
          }}
        >
          <DeleteButton collectionName={"drivers"} carName={dataItem.id} />
          <button className="btn" onClick={() => setEdit(true)}>
            Uredi
          </button>
          {edit && (
            <UpdateUserProfileForm
              initialData={dataItem}
              onClose={() => setEdit(false)}
              // carName={`${dataItem.manufacturer}-${dataItem.model}-${dataItem.licensePlate}`}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DataCardDriver;
