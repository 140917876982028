// MainContent.js
import React from "react";
import CardList from "../CardComponents/CardList";
import MyCalendar from "../CalendarComponents/MyCalendar";
import PDFDocument from "../PDFComponents/PDFdocument";

import { DataProvider } from "../FunctionComponents/DataProvider";
import NotificationBar from "./NotificationBar";

const MainContent = ({ selectedCategory }) => {
  const translateWord = (word) => {
    const translations = {
      cars: "Vozila",
      services: "Servisi",
      drivers: "Vozači",
      fuel: "Kilometraža",
      calendar: "Kalendar",
      warrants: "Putni nalog",
      // Add other translations as needed
    };

    return translations[word] || word;
  };
  return (
    <main>
      {!selectedCategory && <h1>Dobrodošao</h1>}
      {/* <GraphComponent /> */}
      <h1>{translateWord(selectedCategory)}</h1>
      {!selectedCategory && <NotificationBar />}
      {selectedCategory && selectedCategory !== "calendar" && (
        <DataProvider selectedCategory={selectedCategory}>
          <CardList selectedCategory={selectedCategory} />
        </DataProvider>
      )}
      {selectedCategory === "calendar" && (
        <DataProvider selectedCategory={selectedCategory}>
          <MyCalendar />
        </DataProvider>
      )}
      {/* Add conditions for other categories */}
    </main>
  );
};

export default MainContent;
