import React, { useState, useEffect } from "react";

import {
  collection,
  collectionGroup,
  getDocs,
  query,
  where,
  setDoc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import { getFirestore, doc } from "firebase/firestore";
import { app } from "../../firebase/firebase";
import FormSubmitted from "./FormSubmitted";

import { useAuth } from "../../firebase/useAuth";

const WarrantForm = ({ toggleEnable }) => {
  const { userEmail } = useAuth();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const db = getFirestore(app);

  const [cars, setCars] = useState("");
  const [selectedCar, setSelectedCar] = useState({
    manufacturer: "",
    model: "",
    numberOfDoors: 0, // Assuming numberOfDoors is a number
    licensePlate: "",
  });

  const [company, setCompany] = useState("Republički Protokol");
  const [location, setLocation] = useState("Banja Luka");
  const [date, setDate] = useState("");
  const [id, setId] = useState("");
  const [selectedDrivers, setSelectedDrivers] = useState([1]);
  const [drivers, setDrivers] = useState([{ name: "" }]); // Initial state with one driver
  const [route, setRoute] = useState("");
  const [carDetails, setCarDetails] = useState("");
  const [numSeats, setNumSeats] = useState("");
  const [registrationPlate, setRegistrationPlate] = useState("");

  const [lastRecordedYear, setLastRecordedYear] = useState(null);

  const [error, setError] = useState(false);

  function getRandomNumber() {
    // Math.random() generates a random number between 0 (inclusive) and 1 (exclusive)
    const randomFraction = Math.random();

    // Scale the random fraction to the desired range and round it to an integer
    const randomNumber = Math.round(randomFraction * 100000);

    return randomNumber;
  }
  useEffect(() => {
    // Fetch the list of drivers when the component mounts
    const fetchDrivers = async () => {
      const driversCollection = collection(db, "drivers");

      try {
        const driverQuerySnapshot = await getDocs(driversCollection);
        const driverList = driverQuerySnapshot.docs.map((driverDoc) => ({
          id: driverDoc.id,
          ...driverDoc.data(),
        }));
        setDrivers(driverList);
      } catch (error) {
        console.error("Error fetching drivers: ", error);
      }
    };

    fetchDrivers();
  }, [db]);
  useEffect(() => {
    // Fetch the list of cars when the component mounts
    const fetchCars = async () => {
      const carsCollection = collectionGroup(db, "info");

      try {
        const querySnapshot = await getDocs(carsCollection);
        console.log(querySnapshot);
        const carList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log(carList);
        setCars(carList);
      } catch (error) {
        console.error("Error fetching cars: ", error);
      }
    };

    fetchCars();
  }, [db]);
  // Example usage:

  useEffect(() => {
    // Set the current year as the last recorded year when the component mounts
    setLastRecordedYear(new Date().getFullYear().toString());
  }, []);

  // ...

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Company:", company);
    console.log("Location:", location);
    console.log("Date:", date);
    console.log("Selected Drivers:", selectedDrivers);
    console.log("Route:", route);
    console.log("Car Details:", carDetails);
    console.log("Num Seats:", numSeats);
    console.log("Registration Plate:", registrationPlate);
    if (
      !company ||
      !location ||
      !date ||
      selectedDrivers.some((driver) => !driver) ||
      !route
      // !carDetails ||
      // !numSeats ||
      // !registrationPlate
    ) {
      setError(true);
      console.error("All fields are required.");
      return;
    }

    const currentYear = new Date().getFullYear().toString();

    // Check if it's a new year
    if (currentYear !== lastRecordedYear) {
      // If it's a new year, reset the counter
      setLastRecordedYear(currentYear);
      setId(`01/${currentYear}`);
    } else {
      // If it's the same year, increment the counter
      const [counter] = id.split("/");

      // Increment the counter and pad it with zeros if needed
      const newCounter = (parseInt(counter, 10) + 1)
        .toString()
        .padStart(2, "0");

      setId(`${newCounter}/${currentYear}`);
    }

    // Fetch existing car forms for the current year
    const year = new Date().getFullYear();
    const warrantCollection = collection(db, "warrants");
    const yearQuery = query(
      warrantCollection,
      where("date", ">=", `${year}-01-01`),
      where("date", "<=", `${year}-12-31`)
    );
    // Generate the new refId
    const refId = getRandomNumber();
    try {
      const yearQuerySnapshot = await getDocs(yearQuery);
      const carFormsCount = yearQuerySnapshot.size + 1;

      // Generate the new ID
      const newId = `${selectedCar.id}-${
        selectedCar.warrants ? selectedCar.warrants.length + 1 : 1
      }-${year.toString().slice(-2)}`;

      const warrantData = {
        company,
        location,
        date,
        id: newId,
        carId: selectedCar.id,
        refId,
        drivers: selectedDrivers.map((selectedDriver) => ({
          id: selectedDriver,
          name:
            drivers.find((driver) => driver.id === selectedDriver)
              ?.driverName || "",
        })),
        route,
        carDetails: `${selectedCar.manufacturer} - ${selectedCar.model}`,
        numSeats: selectedCar.numberOfDoors,
        registrationPlate: selectedCar.licensePlate,
        user: userEmail,
      };

      const dofRef = doc(db, "warrants", newId);

      // Add the warrant data to Firestore
      await setDoc(dofRef, warrantData);
      console.log("Warrant document successfully written!");
      const carDocRef = doc(
        db,
        "cars",
        `${selectedCar.manufacturer}-${selectedCar.model}-${selectedCar.licensePlate}`,
        "info",
        "car"
      );

      const existingWarrants = selectedCar.warrants || [];
      const updatedWarrants = [...existingWarrants, newId];

      await updateDoc(carDocRef, {
        warrants: updatedWarrants,
      });

      console.log("Car document successfully updated!");
      setFormSubmitted(true);
      // Reset form fields
      setCompany("");
      setLocation("");
      setDate("");
      setId("");
      setDrivers([{ name: "" }]);
      setRoute("");
      setCarDetails("");
      setNumSeats("");
      setRegistrationPlate("");
    } catch (error) {
      console.error("Error writing warrant document: ", error);
    }
  };

  // ...

  const addSelectedDriver = () => {
    if (selectedDrivers.length < 3) {
      setSelectedDrivers([...selectedDrivers, ""]);
    } else {
      console.warn("Maximum number of drivers reached (3).");
    }
  };

  const removeSelectedDriver = (index) => {
    const updatedSelectedDrivers = [...selectedDrivers];
    updatedSelectedDrivers.splice(index, 1);
    setSelectedDrivers(updatedSelectedDrivers);
  };

  const updateSelectedDriver = (index, value) => {
    const updatedSelectedDrivers = [...selectedDrivers];
    updatedSelectedDrivers[index] = value;
    setSelectedDrivers(updatedSelectedDrivers);
  };

  function onClose() {
    setFormSubmitted(false);
  }
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const handleButtonClick = () => {
    setIsButtonClicked(true);
  };

  return (
    <div className="custom-form-container">
      <h2 style={{ paddingBottom: "20px" }}>Dodajte putni nalog</h2>
      <form className="custom-form" onSubmit={handleSubmit}>
        <div className="custom-form-group">
          <label className="custom-label">Firma:</label>
          <input
            className="custom-input"
            type="text"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />
        </div>
        <div className="custom-form-group">
          <label className="custom-label">Mjesto:</label>
          <input
            className="custom-input"
            type="text"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </div>
        <div className="custom-form-group">
          <label className="custom-label">Datum:</label>
          <input
            className="custom-input"
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>

        <div className="custom-form-group">
          <label htmlFor="" className="custom-label">
            Vozači:
          </label>
          {selectedDrivers.map((selectedDriver, index) => (
            <div key={index} style={{ display: "flex", alignItems: "center" }}>
              <select
                name={`selectedDriver${index}`}
                value={selectedDriver}
                onChange={(e) => updateSelectedDriver(index, e.target.value)}
                className="custom-select"
              >
                <option value="">Izaberi vozača</option>
                {drivers.map((driver) => (
                  <option key={driver.id} value={driver.id}>
                    {driver.driverName}
                  </option>
                ))}
              </select>
              {index > 0 && (
                <button
                  type="button"
                  className="deleteBtn"
                  onClick={() => removeSelectedDriver(index)}
                  style={{ marginLeft: "10px", marginTop: "0px" }}
                >
                  Ukloni vozača
                </button>
              )}
            </div>
          ))}
          {selectedDrivers.length < 3 && (
            <button className="btn" type="button" onClick={addSelectedDriver}>
              Dodaj Vozača
            </button>
          )}
        </div>

        <div className="custom-form-group">
          <label className="custom-label">Relacija:</label>
          <input
            className="custom-input"
            type="text"
            value={route}
            onChange={(e) => setRoute(e.target.value)}
          />
        </div>
        <label className="custom-label">Izaberite automobil:</label>
        <select
          className={`custom-select`}
          value={selectedCar.id}
          onChange={(e) => {
            const selectedCarId = e.target.value;
            const selectedCar = cars.find(
              (car) => +car.id === +selectedCarId
            ) || {
              // Provide default values if the car is not found
              manufacturer: "",
              model: "",
              numberOfDoors: 0,
              licensePlate: "",
            };

            setSelectedCar(selectedCar);
          }}
        >
          <option value="">Izaberite automobil</option>
          {cars &&
            cars.map((car) => (
              <option key={car.id} value={car.id}>
                {car.manufacturer}-{car.model}-{car.licensePlate}
              </option>
            ))}
        </select>

        <div className="custom-form-group">
          <label className="custom-label">
            Marka i tip putničkog automobila:
          </label>
          <input
            className="custom-input"
            type="text"
            value={`${selectedCar?.manufacturer || ""}-${
              selectedCar?.model || ""
            }`}
            // onChange={(e) => setCarDetails(e.target.value)}
          />
        </div>

        <div className="custom-form-group">
          <label className="custom-label">Broj sjedista:</label>
          <input
            className="custom-input"
            type="number"
            value={selectedCar?.numberOfDoors || ""}
            onChange={(e) => setNumSeats(e.target.value)}
          />
        </div>

        <div className="custom-form-group">
          <label className="custom-label">Registarska oznaka vozila:</label>
          <input
            className="custom-input"
            type="text"
            value={selectedCar?.licensePlate || ""}
            onChange={(e) => setRegistrationPlate(e.target.value)}
          />
        </div>

        {error && (
          <div className="error-message">Sva polja moraju biti popunjena</div>
        )}
        {isButtonClicked && (
          <>
            <div className="info-box">
              <p>
                Kada potvrdite snimanje putnog naloga, isti se ne može brisati.
              </p>
              <p>Da li ste sigurni da želite prihvatiti?</p>
            </div>
            <button className="custom-submit-button" type="submit">
              Siguran sam, Sačuvaj
            </button>
          </>
        )}

        {/* <div className="info-box">
          <p>
            Kada potvrdite snimanje putnog naloga, isti se ne može brisati ni
            urediti.
          </p>
          <p>Da li ste sigurni da želite prihvatiti?</p>
        </div> */}
        {error && <p>Sva polja se moraju popuniti</p>}
        {!isButtonClicked && (
          <div>
            <button className="custom-submit-button" type="submit">
              Prihvati
            </button>
            <button
              className="deleteBtn"
              style={{ borderRadius: "5px", marginLeft: "10px" }}
              onClick={toggleEnable}
              type="button"
            >
              Odustani
            </button>
          </div>
        )}

        {formSubmitted && <FormSubmitted onClose={onClose} />}
      </form>
    </div>
  );
};

export default WarrantForm;
