import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import "./Login.css";

const Login = () => {
  const auth = getAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const handleLogin = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      // User successfully logged in
      const user = userCredential.user;
      console.log("Logged in user:", user);
    } catch (error) {
      // Handle login errors
      console.error("Login error:", error.message);
      setError("Netačna šifra ili e-mail, pokušaj ponovo!");
    }
  };

  return (
    <div className="login-container">
      <h2 className="login-header">Login</h2>
      <label className="login-label">Email:</label>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="login-input"
      />
      <label className="login-label">Šifra:</label>
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="login-input"
      />
      {error && <p className="login-error">{error}</p>}
      <button onClick={handleLogin} className="login-button">
        Uloguj se
      </button>
    </div>
  );
};

export default Login;
